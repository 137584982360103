import { v4 as uuid } from "uuid";

export const contentTypes = [
  {
    type: "header",
    name: "Rubrik",
    icon: "text",
    uuid: uuid(),
    data: {
      text: "",
    },
  },

  {
    type: "richtext",
    name: "Brödtext",
    icon: "richtext",
    uuid: uuid(),
    data: {
      html: "",
    },
  },
  {
    type: "image",
    name: "Bild",
    icon: "image",
    uuid: uuid(),
    data: {
      file: {},
    },
  },
  {
    type: "ytvideo",
    name: "YouTube-video",
    icon: "link",
    uuid: uuid(),
    data: {
      fullLink: "",
      id: "",
      embedLink: "",
    },
  },
  {
    type: "document",
    name: "Dokument",
    icon: "image",
    onlyOnEvents: true,
    uuid: uuid(),
    data: {
      file: {},
    },
  },
];
