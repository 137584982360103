import { useEffect, useState } from "react";
import styled from "styled-components";
import Button from "../../sharedUI/Button";
import Input from "../../sharedUI/Input";
import { colors } from "../../utils/config";

const StyledDeleteConfirmation = styled.div`
  code {
    background: ${colors.neutral[30]};
    padding: 0.3rem;
    display: inline-block;
  }
`;

const DeleteConfirmation = ({
  item,
  deleteKey,
  handleInvalidKey,
  action,
  name,
  instruction,
}) => {
  const [loading, setLoading] = useState(false);
  const [value, setValue] = useState("");

  useEffect(() => {
    setValue("");
  }, [item]);

  return (
    <StyledDeleteConfirmation>
      <p className="m0 mbottom1">
        Är du säker på att du vill radera {name}? <br />
        Åtgärden går inte att ångra.
      </p>
      <Input
        placeholder={
          instruction ? `Skriv ${instruction} i fältet för att bekräfta` : ""
        }
        value={value}
        onChange={(e) => setValue(e.target.value)}
        disabled={loading}
      />
      <code>{item[deleteKey]}</code>
      <div className="flex jce mtop2">
        <Button
          btnType="danger"
          onClick={() => {
            if (value !== item[deleteKey]) {
              handleInvalidKey();
              return;
            }
            setLoading(true);
            action(
              item,
              () => {
                setLoading(false);
              },
              () => {
                setLoading(false);
              }
            );
          }}
          disabled={value !== item[deleteKey] || loading}
        >
          {loading ? "Raderar..." : "Radera permament"}
        </Button>
      </div>
    </StyledDeleteConfirmation>
  );
};

export default DeleteConfirmation;
