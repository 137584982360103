import { useEffect, useState } from "react";
import styled from "styled-components";
import Input from "../../sharedUI/Input";
import Button from "../../sharedUI/Button";
import Checkbox from "../../sharedUI/Checkbox";
import TagsInput from "../../sharedUI/TagsInput";
import PartEdit from "../../routes/protected/PartEdit";

const StyledDocumentForm = styled.div`
  form {
    max-width: 600px;
  }
`;

const DocumentForm = ({ handleSubmit, data, outlet }) => {
  const initialData = {
    title: "",
    tags: [],
    published: false,
    contents: [],
  };

  const [formData, setFormData] = useState(initialData);
  const [loading, setLoading] = useState(false);

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const onSubmit = (e) => {
    setLoading(true);
    e.preventDefault();
    handleSubmit(
      formData,
      () => {
        setFormData(initialData);
        setLoading(false);
      },
      () => setLoading(false)
    );
  };

  useEffect(() => {
    if (data) setFormData(data);
  }, [data]);

  return (
    <StyledDocumentForm>
      <form onSubmit={onSubmit}>
        <Checkbox
          className="mbottom1"
          label="Publicerad"
          checked={formData.published}
          onChange={(e) =>
            setFormData({
              ...formData,
              published: e.target.checked,
            })
          }
        />

        <Input
          label="Dokumentets namn"
          type="text"
          value={formData.title}
          name="title"
          placeholder="Namn"
          required
          disabled={loading}
          onChange={handleChange}
        />

        <Input
          label="Ingress"
          type="textarea"
          placeholder="Visas på dokumentkortet"
          name="ingress"
          value={formData.ingress}
          disabled={loading}
          onChange={handleChange}
        />
        <TagsInput
          label="Taggar"
          name="tags"
          value={formData.tags}
          onChange={handleChange}
        />
        <p className="label">Innehåll</p>
        <PartEdit
          onEvent
          outlet={outlet}
          setPart={({ contents }) => setFormData({ ...formData, contents })}
          part={{ contents: formData.contents }}
          mTop={"0"}
        />
        <div className="flex jce">
          <Button type="submit" disabled={loading}>
            {loading ? "Sparar..." : "Spara"}
          </Button>
        </div>
      </form>
    </StyledDocumentForm>
  );
};

export default DocumentForm;
