import { useState } from "react";
import { Link, useOutletContext } from "react-router-dom";
import styled from "styled-components";
import Button from "../../sharedUI/Button";
import Card from "../../sharedUI/Card";
import { Loader } from "../../sharedUI/FullScreenLoader";
import IconButton from "../../sharedUI/IconButton";
import Input from "../../sharedUI/Input";
import Tabber from "../../sharedUI/Tabber";
import { fetcher } from "../../utils/fetcher";

const StyledProfile = styled.div`
  .tabber-content {
    margin-top: 1rem;

    .change-password {
      display: flex;
      align-items: flex-start;
      gap: 1rem;
    }
    .card {
      max-width: 600px;
      min-width: 400px;
    }
  }
  ul {
    margin: 0;
    padding: 0;
    padding-left: 1rem;
  }
`;

const Profile = () => {
  const { globalState, notify, setGlobalState } = useOutletContext();
  const { user } = globalState;
  const [activeTab, setActiveTab] = useState(0);
  const [userInfo, setUserInfo] = useState({
    firstName: user?.firstName,
    lastName: user?.lastName,
    email: user?.email,
  });
  const [loading, setLoading] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [passwordInfo, setPasswordInfo] = useState({
    currentPassword: "",
    newPassword: "",
    newPasswordRepeat: "",
  });

  const logout = () => {
    localStorage.removeItem("bg-kurswebb-admin-token");
    setGlobalState({
      ...globalState,
      user: null,
      token: null,
    });
  };

  const handleChangeUser = (e) => {
    setUserInfo({
      ...userInfo,
      [e.target.name]: e.target.value,
    });
  };

  const handleChangePassword = (e) => {
    setPasswordInfo({
      ...passwordInfo,
      [e.target.name]: e.target.value,
    });
  };

  const handleUpdateUser = async (e) => {
    e.preventDefault();
    setLoading(true);
    const { err } = await fetcher({
      path: "admin-auth/self/update-info",
      method: "POST",
      body: userInfo,
      auth: globalState.token,
    });
    if (err) {
      notify.error("Något gick fel");
      setLoading(false);
      return;
    }
    notify.success("Informationen uppdaterades");
    logout();
  };

  const handleUpdatePassword = async (e) => {
    e.preventDefault();
    if (passwordInfo.newPassword !== passwordInfo.newPasswordRepeat) {
      notify.error("Lösenorden stämmer inte överens");
      return;
    }
    setLoading(true);
    const { err } = await fetcher({
      path: "admin-auth/self/update-password",
      body: passwordInfo,
      method: "POST",
      auth: globalState.token,
    });
    if (err) {
      notify.error("Kunde inte uppdatera lösenordet");
      setLoading(false);
      return;
    }
    notify.success("Lösenordet uppdaterades");
    logout();
  };

  return (
    <StyledProfile className="content">
      <Link to="/">
        <IconButton iconLeft="chevron-left" trans className="mtop2">
          Tillbaka
        </IconButton>
      </Link>
      <h1 className="m0 mbottom1">Min profil</h1>
      <Tabber
        tabs={["Mina uppgifter", "Byt lösenord"]}
        activeTab={activeTab}
        setActiveTab={setActiveTab}
      />
      <div className="tabber-content">
        {loading ? (
          <Loader />
        ) : (
          <>
            {activeTab === 0 && (
              <>
                {isEdit ? (
                  <>
                    <p className="m0 b1 mbottom1 danger">
                      Om du ändrar dina uppgifter så måste du logga in igen.
                    </p>
                    <Card className="card">
                      <form onSubmit={handleUpdateUser}>
                        <Input
                          label="Förnamn"
                          disabled={loading}
                          className="m0 mbottom1 b1"
                          value={userInfo.firstName}
                          name="firstName"
                          type="text"
                          placeholder="Förnamn"
                          onChange={handleChangeUser}
                        />

                        <Input
                          label="Efternamn"
                          disabled={loading}
                          className="m0 mbottom1 b1"
                          value={userInfo.lastName}
                          name="lastName"
                          type="text"
                          placeholder="Efternamn"
                          onChange={handleChangeUser}
                        />
                        <Input
                          label="Mailadress"
                          disabled={loading}
                          className="m0 mbottom1 b1"
                          value={userInfo.email}
                          name="email"
                          type="email"
                          placeholder="Mailadress"
                          onChange={handleChangeUser}
                        />
                        <div className="flex jcfe gap05">
                          <Button
                            disabled={loading}
                            btnType="primary"
                            type="submit"
                          >
                            {loading ? "Sparar..." : "Spara"}
                          </Button>
                          <Button
                            disabled={loading}
                            type="button"
                            btnType="neutral"
                            onClick={() => setIsEdit(false)}
                          >
                            Avbryt
                          </Button>
                        </div>
                      </form>
                    </Card>
                  </>
                ) : (
                  <Card className="card">
                    <p className="m0 b2">Förnamn</p>
                    <p className="m0 mbottom1 b1">{user.firstName}</p>
                    <p className="m0 b2">Efternamn</p>
                    <p className="m0 mbottom1 b1">{user.lastName}</p>
                    <p className="m0 b2">Mailadress</p>
                    <p className="m0 mbottom1 b1">{user.email}</p>
                    <Button btnType="primary" onClick={() => setIsEdit(true)}>
                      Ändra
                    </Button>
                  </Card>
                )}
              </>
            )}
            {activeTab === 1 && (
              <>
                <p className="m0 b1 mbottom1 danger">
                  Om du ändrar ditt lösenord så måste du logga in igen.
                </p>
                <div className="change-password">
                  <Card className="card">
                    <form onSubmit={handleUpdatePassword}>
                      <Input
                        label="Nuvarande lösenord"
                        disabled={loading}
                        className="m0 mbottom1 b1"
                        value={passwordInfo.currentPassword}
                        name="currentPassword"
                        type="password"
                        placeholder="Nuvarande lösenord"
                        onChange={handleChangePassword}
                      />

                      <Input
                        label="Nytt lösenord"
                        disabled={loading}
                        className="m0 mbottom1 b1"
                        value={passwordInfo.newPassword}
                        name="newPassword"
                        type="password"
                        placeholder="Nytt lösenord"
                        onChange={handleChangePassword}
                      />
                      <Input
                        label="Upprepa nytt lösenord"
                        disabled={loading}
                        className="m0 mbottom1 b1"
                        value={passwordInfo.newPasswordRepeat}
                        name="newPasswordRepeat"
                        type="password"
                        placeholder="Upprepa nytt lösenord"
                        onChange={handleChangePassword}
                      />
                      <div className="flex jce mtop2">
                        <Button
                          btnType="primary"
                          type="submit"
                          disabled={loading}
                        >
                          {loading ? "Sparar..." : "Spara"}
                        </Button>
                      </div>
                    </form>
                  </Card>
                  <Card className="card">
                    <p className="m0 mbottom1 h3">Lösenordskrav</p>
                    <ul>
                      <li>Minst 8 tecken</li>
                      <li>Minst en liten bokstav</li>
                      <li>Minst en stor bokstav</li>
                      <li>Minst en siffra</li>
                    </ul>
                  </Card>
                </div>
              </>
            )}
          </>
        )}
      </div>
    </StyledProfile>
  );
};

export default Profile;
