import { useEffect, useState } from "react";
import styled from "styled-components";
import Button from "../../sharedUI/Button";
import Input from "../../sharedUI/Input";

const StyledUserForm = styled.div`
  .inputs {
    display: flex;
    flex-direction: column;
  }
`;

const UserForm = ({ handleSubmit, data, header }) => {
  const initialUser = {
    firstName: "",
    lastName: "",
    email: "",
  };

  const [formData, setFormData] = useState(initialUser);
  const [loading, setLoading] = useState(false);

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  useEffect(() => {
    if (data) setFormData(data);
  }, [data]);

  return (
    <StyledUserForm>
      <form
        onSubmit={(e) => {
          setLoading(true);
          e.preventDefault();
          handleSubmit(
            formData,
            () => {
              setFormData(initialUser);
              setLoading(false);
            },
            () => {
              setLoading(false);
            }
          );
        }}
      >
        <div className="inputs">
          <Input
            label="Förnamn"
            type="text"
            value={formData.firstName}
            onChange={handleChange}
            name="firstName"
            required
            disabled={loading}
          />
          <Input
            label="Efternamn"
            type="text"
            value={formData.lastName}
            onChange={handleChange}
            name="lastName"
            required
            disabled={loading}
          />
          <Input
            label="Mailadress"
            type="email"
            value={formData.email}
            onChange={handleChange}
            name="email"
            required
            disabled={loading}
          />
        </div>
        <div className="flex jce">
          <Button type="submit" disabled={loading}>
            {loading ? "Sparar..." : "Spara"}
          </Button>
        </div>
      </form>
    </StyledUserForm>
  );
};

export default UserForm;
