import { useEffect, useState } from "react";
import { useOutletContext } from "react-router-dom";
import styled from "styled-components";
import DeleteConfirmation from "../../components/forms/DeleteConfirmation";
import RegisterLinkForm from "../../components/forms/RegisterLinkForm";
import UserForm from "../../components/forms/UserForm";
import UsersList from "../../components/ui/UsersList";
import { Loader } from "../../sharedUI/FullScreenLoader";
import { fetcher } from "../../utils/fetcher";

const StyledUsers = styled.div`
  padding-bottom: 20rem;
  .tabber-content {
    margin-top: 2rem;
  }
`;

const Users = () => {
  const { globalState, notify, modal, closeModal } = useOutletContext();

  const [users, setUsers] = useState();
  const [courses, setCourses] = useState([]);
  const [loading, setLoading] = useState(true);
  const [progresses, setProgresses] = useState([]);

  const getUsers = async () => {
    setLoading(true);
    const { err, data } = await fetcher({
      path: "admin-auth/users",
      auth: globalState.token,
    });
    if (err) {
      notify.error("Kunde inte hämta användare");
      return;
    }
    setUsers(data.users);
    setCourses(data.courses);
    setProgresses(data.progresses);
    setLoading(false);
  };

  const createUser = async (formData, cb, errCb) => {
    const { err } = await fetcher({
      path: "admin-auth/users",
      method: "POST",
      body: formData,
      auth: globalState.token,
    });
    if (err) {
      notify.error("Kunde inte spara användare");
      errCb();
      return;
    }
    closeModal();
    cb();
    notify.success("Användaren lades till");
    getUsers();
  };

  const createUserInit = () => {
    modal(<UserForm handleSubmit={createUser} />, "Lägg till ledare");
  };

  const deleteUser = async (user, cb, errCb) => {
    const { err } = await fetcher({
      path: `admin-auth/users/${user._id}`,
      method: "DELETE",
      auth: globalState.token,
    });
    if (err) {
      notify.error("Kunde inte radera användaren");
      errCb();
      return;
    }
    notify.success("Användaren raderades");
    cb();
    closeModal();
    getUsers();
  };

  const deleteUserInit = (user) => {
    modal(
      <DeleteConfirmation
        action={deleteUser}
        item={user}
        handleInvalidKey={() => notify.error("Skriv in rätt värde i fältet")}
        deleteKey={"email"}
        name={"ledaren"}
        instruction="ledarens mailadress"
      />,
      `Radera ${user.firstName}?`
    );
  };

  const generateRegisterLinkInit = () => {
    modal(
      <RegisterLinkForm
        generate={generateRegisterLink}
        notify={notify}
        closeModal={closeModal}
      />,
      "Skapa registrerinslänk"
    );
  };

  const generateRegisterLink = async () => {
    const { err, data } = await fetcher({
      path: "admin-auth/generate-register-link",
      auth: globalState.token,
    });
    if (err) return false;
    return data;
  };

  const editUser = async (user, cb, errCb) => {
    const { err } = await fetcher({
      path: `admin-auth/users/${user._id}`,
      method: "POST",
      auth: globalState.token,
      body: user,
    });
    if (err) {
      notify.error("Kunde inte uppdatera användare");
      errCb();
      return;
    }
    closeModal();
    cb();
    getUsers();
    notify.success("Användaren uppdaterades");
  };

  const editUserInit = (user) => {
    modal(<UserForm handleSubmit={editUser} data={user} />, "Redigera ledare");
  };

  useEffect(() => {
    getUsers();
    //eslint-disable-next-line
  }, []);

  return (
    <StyledUsers className="content">
      {loading ? (
        <Loader />
      ) : (
        <div className="tabber-content">
          <UsersList
            generateRegisterLinkInit={generateRegisterLinkInit}
            courses={courses}
            progresses={progresses}
            users={users}
            createUserInit={createUserInit}
            editUserInit={editUserInit}
            deleteUserInit={deleteUserInit}
          />
        </div>
      )}
    </StyledUsers>
  );
};

export default Users;
