import styled from "styled-components";
import SVG from "../../sharedUI/SVG";
import { DOServer } from "../../utils/config";

const StyledMediaThumbnail = styled.div``;

const MediaThumbnail = ({ file, onClick }) => {
  if (!file) return null;
  return (
    <StyledMediaThumbnail onClick={onClick}>
      {file.type === "image" && (
        <img
          src={`${DOServer}${file.uuid}/100.${file.extension}`}
          alt={file.name}
        />
      )}
      {file.type === "video" && <SVG name="video" stroke="black" />}
      {file.type === "pdf" && <SVG name="pdf" stroke="black" />}
    </StyledMediaThumbnail>
  );
};

export default MediaThumbnail;
