import styled from "styled-components";
import { Editor } from "@tinymce/tinymce-react";

const StyledRichTextEdit = styled.div`
  .tox-statusbar {
    display: none !important;
  }
`;

const RichTextEdit = ({ content, setContentData }) => {
  return (
    <StyledRichTextEdit>
      <Editor
        apiKey="8vx5y7rq8czx2uiyjxz384z1tgv52e698san9yj67z6h9z45"
        value={content.data.html}
        init={{
          menubar: false,
          plugins: [
            "advlist",
            "autolink",
            "lists",
            "link",
            "image",
            "charmap",
            "preview",
            "anchor",
            "searchreplace",
            "visualblocks",
            "code",
            "fullscreen",
            "insertdatetime",
            "media",
            "table",
            "code",
            "help",
            "wordcount",
          ],
          default_link_target: "_blank",
          toolbar: "blocks | bold italic underline link  | bullist numlist |",
        }}
        onEditorChange={(html) => {
          setContentData({
            ...content.data,
            html,
          });
        }}
      />
    </StyledRichTextEdit>
  );
};

export default RichTextEdit;
