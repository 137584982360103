import { useState } from "react";
import styled from "styled-components";
import Button from "../../sharedUI/Button";
import Input from "../../sharedUI/Input";
import { v4 as uuid } from "uuid";
import { colors, measurments } from "../../utils/config";
import SVG from "../../sharedUI/SVG";
import Select from "../../sharedUI/Select";

const StyledKnowledgeTestEdit = styled.div`
  .test-question {
    border-left: 1px solid ${colors.neutral[50]};
    border-top: 1px solid ${colors.neutral[50]};
    padding: 0.5rem 0.5rem 0rem 0.5rem;
    .test-question-headers {
      display: flex;
      align-items: center;
      justify-content: space-between;
      color: ${colors.primary.hover};
      border-bottom: 1px solid ${colors.neutral[40]};
      padding-bottom: 0.5rem;
      margin-bottom: 0.5rem;
      .svg-button {
        cursor: pointer;
        svg {
          width: 18px;
          height: 18px;
          margin-right: 0.2rem;
        }
      }
    }
  }
  .options {
    margin-bottom: 2rem;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    .option {
      background: ${colors.neutral[70]};
      color: white;
      padding: 0.3rem 0.5rem;
      border-radius: ${measurments.borderRadius}px;
      margin-bottom: 0.3rem;
      display: flex;
      align-items: center;
      .option-trash {
        cursor: pointer;
        svg {
          width: 16px;
          height: 16px;
        }
      }
      p {
        margin-right: 0.5rem;
      }
    }
  }
`;

const KnowledgeTestEdit = ({ course, setCourse }) => {
  const initialQuestion = {
    type: "multiplechoice",
    question: "",
    options: [],
    correctAnswer: "",
    uuid: "",
  };

  const [optionFields, setOptionFields] = useState([]);

  const handleChange = (e, i) => {
    const questions = Array.from(course.testQuestions);
    questions[i][e.target.name] = e.target.value;
    setCourse({
      ...course,
      testQuestions: questions,
    });
  };

  const deleteQuestion = (i) => {
    const questions = Array.from(course.testQuestions);
    questions.splice(i, 1);
    setCourse({
      ...course,
      testQuestions: questions,
    });
  };

  const addQuestion = () => {
    const questions = Array.from(course.testQuestions);
    questions.push({ ...initialQuestion, uuid: uuid() });
    const temp = [...optionFields];
    temp.push("");
    setOptionFields(temp);
    setCourse({
      ...course,
      testQuestions: questions,
    });
  };

  const removeOption = (i, oi) => {
    const questions = Array.from(course.testQuestions);
    questions[i].options.splice(oi, 1);
    questions[i].correctAnswer = "";
    setCourse({
      ...course,
      testQuestions: questions,
    });
  };

  const handleAddOption = (e, i) => {
    if (e.key === "Enter") {
      const questions = Array.from(course.testQuestions);
      questions[i].options.push(optionFields[i]);
      setCourse({
        ...course,
        testQuestions: questions,
      });
      const temp = [...optionFields];
      temp[i] = "";
      setOptionFields(temp);
    }
  };

  return (
    <StyledKnowledgeTestEdit>
      {course.testQuestions.map((question, i) => (
        <div className="test-question" key={question.uuid}>
          <div className="test-question-headers">
            <p className="m0 b1">Fråga {i + 1}</p>
            <div className="svg-button" onClick={() => deleteQuestion(i)}>
              <SVG name="trash" stroke={colors.primary.hover} />
            </div>
          </div>
          <Input
            type="text"
            label="Frågeställning"
            value={question.question}
            name="question"
            onChange={(e) => handleChange(e, i)}
          />
          <Input
            type="text"
            label="Svarsalternativ"
            value={optionFields[i]}
            placeholder="Skriv in svarsalternativet och tryck på Enter"
            onChange={(e) => {
              const temp = [...optionFields];
              temp[i] = e.target.value;
              setOptionFields(temp);
            }}
            onKeyDown={(e) => handleAddOption(e, i)}
          />
          {!!question.options.length && (
            <div className="options">
              {question.options.map((option, oi) => (
                <div
                  className="option b2"
                  key={`${question.uuid}__${option}_${oi}`}
                >
                  <p className="b2 m0"> {option}</p>
                  <div
                    className="option-trash"
                    onClick={() => removeOption(i, oi)}
                  >
                    <SVG name="trash" stroke="white" />
                  </div>
                </div>
              ))}
            </div>
          )}
          <Select
            label="Rätt svar"
            value={question.correctAnswer}
            name="correctAnswer"
            onChange={(e) => handleChange(e, i)}
          >
            <option value="" disabled>
              Välj vilket svar som är rätt
            </option>
            {question.options.map((option, oi) => (
              <option
                key={`${question.uuid}_${option}_${oi}_answer`}
                value={option}
              >
                {option}
              </option>
            ))}
          </Select>
        </div>
      ))}
      <Button btnType="neutral" className="mbottom1" onClick={addQuestion}>
        Lägg till fråga
      </Button>
    </StyledKnowledgeTestEdit>
  );
};

export default KnowledgeTestEdit;
