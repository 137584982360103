import styled from "styled-components";
import PartEdit from "../../routes/protected/PartEdit";
import ExpandCard from "../ui/ExpandCard";
import { v4 as uuid } from "uuid";
import ImageEdit from "../contentComponents/edit/ImageEdit";
import IconButton from "../../sharedUI/IconButton";
import Input from "../../sharedUI/Input";
import { colors, measurments } from "../../utils/config";
import Textarea from "../../sharedUI/Textarea";
import Checkbox from "../../sharedUI/Checkbox";
import SVG from "../../sharedUI/SVG";
import KnowledgeTestEdit from "./KnowledgeTestEdit";

const StyledMainContent = styled.div`
  margin-right: 1rem;
  .course-name-container {
    input {
      border: none;
      padding: 1rem 1.5rem;
      font-family: "SofiaPro-Regular", sans-serif;
      font-size: 1rem;
      line-height: 1.5rem;
      border-radius: ${measurments.borderRadius}px;
      box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1);
      &:focus {
        outline: none;
      }
    }
    position: relative;
    .course-name-icon {
      height: 100%;
      display: flex;
      align-items: center;
      position: absolute;
      pointer-events: none;
      top: 0;
      bottom: 0;
      right: 1.5rem;
    }
  }
  .visibility-status-container {
    background: white;
    border-radius: ${measurments.borderRadius}px;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1);
    padding: 1rem 1.5rem;
    margin-bottom: 1rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
`;

const MainContent = ({ course, setCourse }) => {
  const movePartDown = (partIndex) => {
    const parts = Array.from(course.parts);
    const [reordered] = parts.splice(partIndex, 1);
    parts.splice(partIndex + 1, 0, reordered);
    setCourse({
      ...course,
      parts,
    });
  };

  const movePartUp = (partIndex) => {
    const parts = Array.from(course.parts);
    const [reordered] = parts.splice(partIndex, 1);
    parts.splice(partIndex - 1, 0, reordered);
    setCourse({
      ...course,
      parts,
    });
  };

  const handleChangeBasics = (e) => {
    setCourse({
      ...course,
      [e.target.name]: e.target.value,
    });
  };

  const setPart = (data, partIndex) => {
    const tempParts = [...course.parts];
    tempParts[partIndex] = data;
    setCourse({
      ...course,
      parts: tempParts,
    });
  };

  const addPart = () => {
    const tempParts = [...course.parts];

    tempParts.push({
      name: `Delmoment ${course.parts.length + 1}`,
      contents: [],
      uuid: uuid(),
    });
    setCourse({
      ...course,
      parts: tempParts,
    });
  };

  const deletePart = (partIndex) => {
    const tempParts = Array.from(course.parts);
    tempParts.splice(partIndex, 1);
    setCourse({
      ...course,
      parts: tempParts,
    });
  };

  return (
    <StyledMainContent>
      <div className="course-name-container">
        <Input
          type="text"
          value={course.name}
          name="name"
          placeholder="Kursnamn"
          onChange={handleChangeBasics}
        />
        <div className="course-name-icon">
          <SVG name="edit" stroke={colors.primary.hover} />
        </div>
      </div>
      <div className="visibility-status-container">
        <p className="m0 b1">
          Synlighet: {course.published ? "Publik" : "Ej publik"}
        </p>
        <Checkbox
          checked={course.published}
          onChange={(e) =>
            setCourse({
              ...course,
              published: e.target.checked,
            })
          }
        />
      </div>
      <ExpandCard
        headerContent={<h2 className="b1 m0">Kursinformation</h2>}
        mainContent={
          <div className="basics">
            <Textarea
              label="Kort beskrivning"
              value={course.description.join("\n")}
              placeholder="Kort beskrivning"
              name="description"
              onChange={(e) =>
                setCourse({
                  ...course,
                  [e.target.name]: e.target.value.split("\n"),
                })
              }
            />
            <Textarea
              h={20}
              label="Introduktion"
              value={course.descriptionLong.join("\n")}
              placeholder="Introduktion"
              name="descriptionLong"
              onChange={(e) =>
                setCourse({
                  ...course,
                  [e.target.name]: e.target.value.split("\n"),
                })
              }
            />
            <p className="m0 label">Huvudbild</p>
            <ImageEdit
              initiallyClosed
              setContentData={(content) => {
                setCourse({
                  ...course,
                  mainImage: content.file,
                });
              }}
              content={{
                data: {
                  file: course.mainImage,
                },
              }}
            />
          </div>
        }
      />
      {course.parts.map((part, partIndex) => (
        <ExpandCard
          key={part.uuid}
          buttons={
            <>
              {partIndex !== course.parts.length - 1 && (
                <div
                  className="svg-button"
                  onClick={() => movePartDown(partIndex)}
                >
                  <SVG name="arrow-down-square" stroke={colors.primary.hover} />
                </div>
              )}
              {partIndex !== 0 && (
                <div
                  className="svg-button"
                  onClick={() => movePartUp(partIndex)}
                >
                  <SVG name="arrow-up-square" stroke={colors.primary.hover} />
                </div>
              )}
              <div className="svg-button" onClick={() => deletePart(partIndex)}>
                <SVG name="trash" stroke={colors.primary.hover} />
              </div>
            </>
          }
          headerContent={
            <input
              className="header-content-input"
              value={part.name}
              onChange={(e) => {
                setPart(
                  {
                    ...part,
                    name: e.target.value,
                  },
                  partIndex
                );
              }}
            />
          }
          mainContent={
            <PartEdit
              part={part}
              setPart={(data) => setPart(data, partIndex)}
            />
          }
        />
      ))}

      <div className="flex jce mbottom1">
        <IconButton iconRight="plus-circle" onClick={addPart}>
          Lägg till delmoment
        </IconButton>
      </div>
      <ExpandCard
        headerContent={<h3 className="m0 b1">Kunskapstest</h3>}
        mainContent={
          <KnowledgeTestEdit course={course} setCourse={setCourse} />
        }
      />
    </StyledMainContent>
  );
};

export default MainContent;
