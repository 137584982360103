import styled from "styled-components";
import Input from "../../../sharedUI/Input";
import { youtubeUrl } from "../../../utils/youtubeUrl";

const StyledYoutubeEdit = styled.div`
  .iframe-container {
    position: relative;
    width: 100%;
    padding-bottom: 56.25%;
    height: 0;
    margin-bottom: 2rem;
  }
  .iframe-container iframe {
    border: none;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`;

const YoutubeEdit = ({ content, setContentData }) => {
  const setVideo = (e) => {
    const val = e.target.value;
    const id = youtubeUrl(val);
    const embedLink = `https://www.youtube.com/embed/${id}`;
    setContentData({
      ...content.data,
      fullLink: val,
      id: id || "",
      embedLink: id ? embedLink : "",
    });
  };

  return (
    <StyledYoutubeEdit>
      <Input
        label="Youtube-länk"
        type="text"
        placeholder="Klistra in YouTubelänk"
        value={content.data.fullLink}
        onChange={setVideo}
      />

      {content.data.fullLink && (
        <>
          <p className="m0 label">Förhandsvisning</p>
          {content.data.embedLink ? (
            <div className="iframe-container">
              <iframe
                width="1920"
                height="1080"
                src={content.data.embedLink}
                title="YouTube video player"
                frameBorder="0"
                allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              />
            </div>
          ) : (
            <p className="m0 danger">Felaktig länk</p>
          )}
        </>
      )}
    </StyledYoutubeEdit>
  );
};

export default YoutubeEdit;
