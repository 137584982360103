import { Link, useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components";
import { breakPoints, measurments } from "../../utils/config";
import IconButton from "../../sharedUI/IconButton";
import Tabber from "../../sharedUI/Tabber";

const StyledNavigation = styled.div`
  .nav-inner {
    height: ${measurments.navHeight}px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .logo-mobile {
      display: none;
    }
    .logo {
      width: 200px;
    }
    .nav-right {
      display: flex;
      align-items: center;
      button {
        margin-left: 1rem;
      }
    }
  }
  &.hide-nav {
    .nav-lower {
      display: none;
    }
  }
  ${breakPoints.tablet} {
    .nav-upper {
      .nav-inner {
        .nav-logo {
          .logo {
            display: none;
          }
          .logo-mobile {
            display: block;
            width: 100%;
            max-height: calc(${measurments.navHeight}px - 2rem);
            object-fit: contain;
            object-position: left;
          }
        }
        .nav-right {
          button {
            span {
              display: none;
            }
          }
        }
      }
    }
  }
`;

const Navigation = ({ globalState, setGlobalState, hideNav }) => {
  const location = useLocation();
  const navigate = useNavigate();

  const logout = () => {
    localStorage.removeItem("bg-kurswebb-admin-token");
    setGlobalState({
      ...globalState,
      user: null,
      token: null,
    });
  };

  const navLinks = [
    {
      text: "Ledare",
      to: "users",
    },
    {
      text: "Kurser",
      to: "courses",
    },
    {
      text: "Tränarguide",
      to: "guide",
    },
    {
      text: "Träningsplaneringar",
      to: "plans",
    },
    {
      text: "Evenemang",
      to: "events",
    },
    {
      text: "Dokument",
      to: "documents",
    },
    {
      text: "Mediabibliotek",
      to: "media",
    },
  ];

  const activeRoute = location.pathname.split("/")[1];
  const activeTab = navLinks.indexOf(
    navLinks.filter((l) => l.to === activeRoute)[0]
  );
  const setActiveTab = (i) => {
    navigate(`/${navLinks[i].to}`);
  };

  return (
    <StyledNavigation className={`${hideNav ? "hide-nav" : ""}`}>
      {globalState.user && (
        <>
          <div className="nav-upper">
            <div className="nav-inner content">
              <Link to="/">
                <div className="nav-logo">
                  <img
                    src="/images/logo-horizontal.png"
                    alt="Brommagymnasterna"
                    className="logo"
                  />
                  <img
                    src="/images/logo-symbol.png"
                    alt="Brommagymnasterna"
                    className="logo-mobile"
                  />
                </div>
              </Link>
              <div className="nav-right">
                <Link to="/profile">
                  <IconButton iconLeft="user">Profil</IconButton>
                </Link>
                <IconButton onClick={logout} iconLeft="log-out">
                  Logga ut
                </IconButton>
              </div>
            </div>
          </div>
          <div className="nav-lower content">
            <p className="m0 mtop2">Admin</p>
            <h1 className="m0 mbottom1">Tränarportalen</h1>
            <Tabber
              tabs={navLinks.map((l) => l.text)}
              activeTab={activeTab}
              setActiveTab={setActiveTab}
            />
          </div>
        </>
      )}
    </StyledNavigation>
  );
};

export default Navigation;
