import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { Loader } from "../../sharedUI/FullScreenLoader";

const StyledDashboard = styled.div``;

const Dashboard = () => {
  const navigate = useNavigate();

  useEffect(() => {
    navigate("/users", { replace: true });
    //eslint-disable-next-line
  }, []);

  return (
    <StyledDashboard className="content">
      <Loader />
    </StyledDashboard>
  );
};

export default Dashboard;
