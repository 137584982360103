import { useEffect, useState } from "react";
import styled from "styled-components";
import Input from "../../sharedUI/Input";
import Button from "../../sharedUI/Button";
import Checkbox from "../../sharedUI/Checkbox";
import TagsInput from "../../sharedUI/TagsInput";
import FileInput from "../../sharedUI/FileInput";

const StyledPlanForm = styled.div`
  form {
    max-width: 600px;
  }
`;

const PlanForm = ({ handleSubmit, data, outlet }) => {
  const initialData = {
    title: "",
    file: null,
    tags: [],
    published: false,
  };

  const [formData, setFormData] = useState(initialData);
  const [loading, setLoading] = useState(false);

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const onSubmit = (e) => {
    setLoading(true);
    e.preventDefault();
    handleSubmit(
      { ...formData, sort: 0 },
      () => {
        setFormData(initialData);
        setLoading(false);
      },
      () => setLoading(false)
    );
  };

  useEffect(() => {
    if (data) setFormData(data);
  }, [data]);

  return (
    <StyledPlanForm>
      <form onSubmit={onSubmit}>
        <Checkbox
          className="mbottom1"
          label="Publicerad"
          checked={formData.published}
          onChange={(e) =>
            setFormData({
              ...formData,
              published: e.target.checked,
            })
          }
        />
        <Input
          label="Planeringens namn"
          type="text"
          value={formData.title}
          name="title"
          placeholder="Planeringens namn"
          required
          disabled={loading}
          onChange={handleChange}
        />
        <TagsInput
          label="Taggar"
          name="tags"
          value={formData.tags}
          onChange={handleChange}
        />
        <FileInput
          type="pdf"
          label="Dokument"
          outlet={outlet}
          value={formData.file}
          name="file"
          onChange={handleChange}
        />
        <div className="flex jce">
          <Button type="submit" disabled={loading}>
            {loading ? "Sparar..." : "Spara"}
          </Button>
        </div>
      </form>
    </StyledPlanForm>
  );
};

export default PlanForm;
