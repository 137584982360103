import React, { useEffect, useMemo, useState } from "react";
import { useOutletContext } from "react-router-dom";
import styled from "styled-components";
import { Loader } from "../../sharedUI/FullScreenLoader";
import IconButton from "../../sharedUI/IconButton";
import { fetcher } from "../../utils/fetcher";
import DocumentForm from "../../components/forms/DocumentForm";
import DocumentEntryCard from "../../components/cards/DocumentEntryCard";
import DeleteConfirmation from "../../components/forms/DeleteConfirmation";
import Input from "../../sharedUI/Input";

const StyledDocumentsPage = styled.div`
  .documents-top {
    display: flex;
    justify-content: space-between;
    &-right {
      display: flex;
      align-items: center;
      gap: 0.5rem;
      .search-input {
        margin: 0;
      }
    }
  }
  .entries {
    margin-top: 1rem;
  }
`;

const DocumentsPage = () => {
  const outlet = useOutletContext();
  const { globalState, notify, modal, closeModal } = outlet;

  //State
  const [documents, setDocuments] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchString, setSearchString] = useState("");

  //Functions
  const getEntries = async () => {
    setLoading(true);
    const { err, data } = await fetcher({
      path: "documents/admin",
      auth: globalState.token,
    });
    if (err) {
      notify.error("Kunde inte hämta dokument");
    } else {
      setDocuments(data);
    }
    setLoading(false);
  };

  const createEntry = async (formData, cb, errCb) => {
    const { err } = await fetcher({
      path: "documents",
      body: formData,
      method: "POST",
      auth: globalState.token,
    });
    if (err) {
      errCb();
      notify.error("Kunde inte skapa dokument");
      return;
    }
    cb();
    closeModal();
    getEntries();
  };

  const editEntry = async (formData, cb, errCb) => {
    const { err } = await fetcher({
      path: `documents/${formData._id}`,
      body: formData,
      method: "POST",
      auth: globalState.token,
    });
    if (err) {
      errCb();
      notify.error("Kunde inte uppdatera dokument");
      return;
    }
    cb();
    closeModal();
    getEntries();
  };

  const deleteEntry = async (entry, cb, errCb) => {
    const { err } = await fetcher({
      path: `documents/${entry._id}`,
      method: "DELETE",
      auth: globalState.token,
    });
    if (err) {
      errCb();
      notify.error("Kunde inte radera dokument");
      return;
    }
    cb();
    closeModal();
    getEntries();
  };

  const deleteEntryInit = (entry) => {
    modal(
      <DeleteConfirmation
        item={entry}
        deleteKey={"title"}
        handleInvalidKey={() => notify.error("Fyll i rätt värde i fältet")}
        action={deleteEntry}
        name="dokumentet"
        instruction="dokumentets titel"
      />,
      "Radera dokument"
    );
  };

  const editEntryInit = (entry) => {
    modal(
      <DocumentForm data={entry} handleSubmit={editEntry} outlet={outlet} />,
      "Redigera dokument"
    );
  };

  const createEntryInit = () => {
    modal(
      <DocumentForm handleSubmit={createEntry} outlet={outlet} />,
      "Skapa nytt dokument"
    );
  };

  useEffect(() => {
    getEntries();
    // eslint-disable-next-line
  }, []);

  const filteredEntries = useMemo(() => {
    if (!searchString) return documents;
    const s = searchString.toLocaleLowerCase();
    let matches = [];
    documents.forEach((entry) => {
      const search = entry.title.toLowerCase().search(s);
      if (search !== -1) matches.push(entry);
    });
    return matches;
  }, [documents, searchString]);

  return (
    <StyledDocumentsPage className="content ptop2">
      {loading ? (
        <Loader />
      ) : (
        <>
          <div className="documents-top">
            <h2 className="m0 h2">Dokument</h2>
            <div className="documents-top-right">
              <Input
                value={searchString}
                onChange={(e) => setSearchString(e.target.value)}
                type="text"
                placeholder="Sök dokument"
                className="search-input"
              />
              <IconButton iconRight="plus" onClick={createEntryInit}>
                Skapa nytt
              </IconButton>
            </div>
          </div>
          <div className="entries">
            {filteredEntries.length ? (
              filteredEntries.map((entry) => (
                <DocumentEntryCard
                  key={entry._id}
                  entry={entry}
                  deleteInit={deleteEntryInit}
                  editInit={editEntryInit}
                />
              ))
            ) : (
              <p>Inga dokument hittades</p>
            )}
          </div>
        </>
      )}
    </StyledDocumentsPage>
  );
};

export default DocumentsPage;
