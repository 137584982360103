import styled from "styled-components";
import { measurments, shadows } from "../../utils/config";
import Button from "../../sharedUI/Button";

const StyledTagCard = styled.div`
  margin-bottom: 1rem;
  background: white;
  border-radius: ${measurments.borderRadius}px;
  box-shadow: ${shadows[2]};
  padding: 1.5rem 2.2rem 1rem 1.5rem;
  display: flex;
  justify-content: space-between;
`;

const TagCard = ({ entry, deleteInit, editInit }) => {
  return (
    <StyledTagCard>
      <div>
        <p className="h3 m0">{entry.text}</p>
        {typeof entry.sortIndex === "number" && (
          <p className="b3 m0 light">Sortering: {entry.sortIndex}</p>
        )}
      </div>
      <div className="flex mtop2 ac jce gap05">
        <Button onClick={() => editInit(entry)}>Redigera</Button>
        <Button onClick={() => deleteInit(entry)} btnType="danger">
          Radera
        </Button>
      </div>
    </StyledTagCard>
  );
};

export default TagCard;
