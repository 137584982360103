import { useState } from "react";
import styled from "styled-components";
import ResponsiveImage from "../view/ResponsiveImage";
import MediaLibrary from "../../ui/MediaLibrary";
import Button from "../../../sharedUI/Button";
import { measurments } from "../../../utils/config";
import SVG from "../../../sharedUI/SVG";

const StyledImageEdit = styled.div`
  margin-bottom: 1rem;
  .library-container {
    position: fixed;
    inset: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(10, 10, 10, 0.25);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10;
    &-inner {
      background: white;
      padding: 1rem;
      border-radius: ${measurments.borderRadius}px;
      box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1);
      max-width: calc(${measurments.contentWidth}px - 1rem);
      width: 100%;
      max-height: 80vh;
      height: 100%;
      overflow-y: scroll;
      position: relative;
      .library-container-close {
        position: absolute;
        top: 0.5rem;
        right: 0.5rem;
        button {
          padding: 0.3rem 0.5rem;
          svg {
            width: 16px;
            height: 16px;
          }
        }
      }
    }
  }
`;

const ImageEdit = ({ content, setContentData, outlet }) => {
  //States
  const [libraryOpen, setLibraryOpen] = useState(false);

  //Functions
  const selectImage = (image) => {
    setContentData({
      ...content.data,
      file: image,
    });
    setLibraryOpen(false);
  };

  return (
    <StyledImageEdit>
      <ResponsiveImage file={content.data.file} />
      <div className="flex jce mtop05">
        <Button btnType="neutral" onClick={() => setLibraryOpen(true)}>
          {content.data.file?.name ? "Byt bild" : "Välj bild"}
        </Button>
      </div>
      {libraryOpen && (
        <div className="library-container">
          <div className="library-container-inner">
            <div className="library-container-close">
              <Button btnType="neutral" onClick={() => setLibraryOpen(false)}>
                <SVG name="x" stroke="black" />
              </Button>
            </div>
            <MediaLibrary
              handleSelect={selectImage}
              type="image"
              outlet={outlet}
            />
          </div>
        </div>
      )}
    </StyledImageEdit>
  );
};

export default ImageEdit;
