import { useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import Button from "../../sharedUI/Button";
import IconButton from "../../sharedUI/IconButton";
import Input from "../../sharedUI/Input";
import SVG from "../../sharedUI/SVG";
import { breakPoints, colors } from "../../utils/config";
import { parseDate } from "../../utils/parseDate";

const StyledUsersList = styled.div`
  .users-main-headers {
    display: flex;
    justify-content: space-between;
    align-items: center;
    &-right {
      display: flex;
      align-items: center;
      .search-input {
        margin: 0;
        margin-right: 0.5rem;
      }
    }
    margin-bottom: 0.7rem;
  }
  .users-list-container {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  .users-list-headers {
    background: ${colors.neutral[20]};
  }
  .list-grid {
    p {
      margin: 0;
    }
    padding-left: 1rem;
    padding-right: 1rem;
    padding-top: 0.7rem;
    padding-bottom: 0.7rem;
    display: grid;
    grid-template-columns: 3fr 3fr 2fr 2fr 24px;
    gap: 1rem;
    align-items: center;
  }
  ${breakPoints.tablet} {
    .users-main-headers {
      display: block;
      &-right {
        justify-content: flex-end;
      }
    }
    .list-grid {
      grid-template-columns: 4fr 2fr 24px;
      .email,
      .time-since {
        display: none;
      }
    }
  }
`;

const UsersList = ({
  users,
  createUserInit,
  editUserInit,
  deleteUserInit,
  generateRegisterLinkInit,
  courses,
  progresses,
}) => {
  const [searchString, setSearchString] = useState("");
  const [filteredUsers, setFilteredUsers] = useState([]);

  useEffect(() => {
    if (searchString) {
      const s = searchString.toLocaleLowerCase();
      let matches = [];
      users.forEach((object) => {
        const name = `${object.firstName} ${object.lastName}`;
        const search = name.toLowerCase().search(s);
        if (search !== -1) matches.push(object);
      });
      setFilteredUsers(matches);
    } else setFilteredUsers(users);
    //eslint-disable-next-line
  }, [searchString, users]);

  return (
    <StyledUsersList>
      <div className="users-main-headers">
        <h2 className="m0 h2">Ledare</h2>
        <div className="users-main-headers-right">
          <Input
            value={searchString}
            onChange={(e) => setSearchString(e.target.value)}
            type="text"
            placeholder="Sök ledare"
            className="search-input"
          />
          <div className="flex gap05">
            <IconButton iconRight="plus" onClick={createUserInit}>
              Lägg till ny
            </IconButton>
            <IconButton iconRight="plus" onClick={generateRegisterLinkInit}>
              Skapa registreringslänk
            </IconButton>
          </div>
        </div>
      </div>
      <div className="users-list-container">
        <div className="users-list-headers list-grid">
          <p>Namn</p>
          <p className="email">Mailadress</p>
          <p>Status</p>
          <p className="time-since">Senaste inloggning</p>
        </div>
        {filteredUsers.map((user) => (
          <ListUser
            progresses={progresses}
            courses={courses}
            user={user}
            key={user._id}
            editUserInit={editUserInit}
            deleteUserInit={deleteUserInit}
          />
        ))}
      </div>
    </StyledUsersList>
  );
};

export default UsersList;

const StyledListUser = styled.div`
  background: white;
  transition: 200ms ease-in-out;
  .expand-button {
    cursor: pointer;
  }
  .user-list-expand-container {
    max-height: 0;
    overflow: hidden;
    padding: 0;
    margin: 0;
    padding-left: 1rem;
    padding-right: 1rem;
    transition: 200ms ease-in-out;

    .completion-statuses {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      gap: 1rem;
      margin-bottom: 3rem;
      padding-top: 0.8rem;
      margin-top: 0.5rem;
      border-top: 2px solid ${colors.neutral[40]};
      .completion-status-single {
        .completion-status-name {
          span {
            background: ${colors.warning.bgColor};
            color: ${colors.warning.main};
            border: 1px solid ${colors.warning.border};
            font-size: 12px;
            font-weight: 500;
            line-height: 16px;
            padding: 2px 8px;
            border-radius: 50px;
            margin-left: 0.5rem;
            white-space: nowrap;
            &.green {
              background: ${colors.success.bgColor};
              color: ${colors.success.main};
              border-color: ${colors.success.border};
            }
            &.red {
              background: ${colors.danger.bgColor};
              color: ${colors.danger.main};
              border-color: ${colors.danger.border};
            }
          }
        }
        .completion-status-date {
          color: ${colors.neutral[70]};
        }
      }
    }
  }
  .completion-status {
    border: 1px solid black;
    justify-self: flex-start;
    border-radius: 50px;
    font-size: 12px;
    line-height: 16px;
    padding: 2px 8px;
    &.Slutförd {
      background: ${colors.success.bgColor};
      border-color: ${colors.success.border};
      color: ${colors.success.main};
    }
    &.Ej.påbörjat {
      background: ${colors.danger.bgColor};
      border-color: ${colors.danger.border};
      color: ${colors.danger.main};
    }
    &.Påbörjat {
      background: ${colors.warning.bgColor};
      border-color: ${colors.warning.border};
      color: ${colors.warning.main};
    }
  }
  &.expanded {
    background: ${colors.neutral[30]};
    .user-list-expand-container {
      max-height: 500px;
      overflow-y: scroll;
      padding: 1rem;
    }
  }
  .mobile-additional-info {
    display: none;
  }
  ${breakPoints.tablet} {
    .mobile-additional-info {
      display: block;
    }
    .user-list-expand-container {
      .completion-statuses {
        display: block;
        .completion-status-single {
          margin-bottom: 1rem;
        }
      }
    }
  }
`;

const ListUser = ({
  user,
  deleteUserInit,
  editUserInit,
  courses: _courses,
  progresses: _progresses,
}) => {
  const progresses = useMemo(() => {
    return _progresses.filter((progress) => progress.user === user._id);
  }, [_progresses, user]);

  const courses = useMemo(() => {
    return _courses.map((course) => {
      const progress = progresses.find(
        (progress) => progress.course === course._id
      );
      let status;
      if (!progress) status = "Ej påbörjad";
      if (progress && progress.completionPercent !== 100)
        status = `${parseInt(progress.completionPercent)}% genomförd`;
      if (progress?.testCompleted) status = "Godkänd";
      return {
        ...course,
        progress,
        status,
      };
    });
  }, [_courses, progresses]);

  const completionStatus = useMemo(() => {
    if (!progresses.length) return "Ej påbörjat";
    if (progresses.filter((p) => p.testCompleted).length === courses.length)
      return "Slutförd";
    else return "Påbörjat";
  }, [progresses, courses]);

  const numberOfFinished = useMemo(() => {
    return progresses.filter((p) => p.testCompleted).length;
  }, [progresses]);

  const getTimeSince = (t) => {
    if (!t) return "Aldrig";
    const ONE_SECOND = 1000;
    const ONE_MINUTE = ONE_SECOND * 60;
    const ONE_HOUR = ONE_MINUTE * 60;
    const ONE_DAY = ONE_HOUR * 24;
    const ONE_WEEK = ONE_DAY * 7;
    const ONE_MONTH = ONE_DAY * 30.4;
    const ONE_YEAR = ONE_DAY * 365;
    const thenMs = new Date(t).getTime();
    const nowNs = new Date().getTime();
    const diffMs = nowNs - thenMs;
    if (diffMs > ONE_YEAR) return `${parseInt(diffMs / ONE_YEAR)} år sedan`;
    if (diffMs > ONE_MONTH)
      return `${parseInt(diffMs / ONE_MONTH)} månader sedan`;
    if (diffMs > ONE_WEEK) return `${parseInt(diffMs / ONE_WEEK)} veckor sedan`;
    if (diffMs > ONE_DAY) return `${parseInt(diffMs / ONE_DAY)} dagar sedan`;
    if (diffMs > ONE_HOUR)
      return `${parseInt(diffMs / ONE_HOUR)} timm${
        parseInt(diffMs / ONE_HOUR) === 1 ? "e" : "ar"
      } sedan`;
    if (diffMs > ONE_MINUTE)
      return `${parseInt(diffMs / ONE_MINUTE)} minuter sedan`;
    if (diffMs > ONE_SECOND)
      return `${parseInt(diffMs / ONE_SECOND)} sekunder sedan`;
    else return "Alldeles nyss";
  };

  const [expanded, setExpanded] = useState(false);

  return (
    <StyledListUser className={`${expanded ? "expanded" : ""}`}>
      <div className="list-grid">
        <p>
          {user.firstName} {user.lastName}
        </p>
        <p className="email">{user.email}</p>
        <p className={`completion-status ${completionStatus}`}>
          {completionStatus}
        </p>
        <p className="time-since">{getTimeSince(user.lastLogin)}</p>
        <div className="expand-button" onClick={() => setExpanded(!expanded)}>
          <SVG
            name={expanded ? "chevron-up" : "chevron-down"}
            stroke={colors.neutral[80]}
          />
        </div>
      </div>
      <div className="user-list-expand-container">
        <div className="mobile-additional-info">
          <p className="b1 m0">Mailadress</p>
          <p className="b2 m0 mbottom05">{user.email}</p>
          <p className="b1 m0">Senaste inloggning</p>
          <p className="b2 m0 mbottom1">{getTimeSince(user.lastLogin)}</p>
        </div>
        <p className="h3 m0">
          Utbildningsstatus: {numberOfFinished} /{courses.length} avklarade
        </p>
        <div className="completion-statuses">
          {courses.map((course) => (
            <div
              className="completion-status-single"
              key={`${course.name}_${user._id}`}
            >
              <div className="flex">
                <p className="completion-status-name m0 b1">
                  {course.name}:{" "}
                  <span
                    className={`${course.status === "Godkänd" ? "green" : ""} ${
                      course.status === "Ej påbörjad" ? "red" : ""
                    }`}
                  >
                    {course.status}
                  </span>
                </p>
              </div>
              <p className="completion-status-date m0 b2">
                {parseDate(course.progress?.lastUpdateAt)}
              </p>
            </div>
          ))}
        </div>
        <div className="flex ac jce gap05">
          <Button btnType="neutral" onClick={() => editUserInit(user)}>
            Redigera
          </Button>
          <Button btnType="danger" onClick={() => deleteUserInit(user)}>
            Radera
          </Button>
        </div>
      </div>
    </StyledListUser>
  );
};
