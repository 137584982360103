import { useEffect, useMemo, useState } from "react";
import { Link, useNavigate, useOutletContext } from "react-router-dom";
import { fetcher } from "../../utils/fetcher";
import styled from "styled-components";
import CourseForm from "../../components/forms/CourseForm";
import DeleteConfirmation from "../../components/forms/DeleteConfirmation";
import { v4 as uuid } from "uuid";
import { Loader } from "../../sharedUI/FullScreenLoader";
import Button from "../../sharedUI/Button";
import { breakPoints, colors, measurments, shadows } from "../../utils/config";
import ResponsiveImage from "../../components/contentComponents/view/ResponsiveImage";
import IconButton from "../../sharedUI/IconButton";
import SVG from "../../sharedUI/SVG";
import Tag from "../../sharedUI/Tag";

const StyledCourses = styled.div`
  .courses-top {
    display: flex;
    justify-content: space-between;
    align-items: end;
    .courses-top-left {
      .tags {
        margin-top: 0.5rem;
        display: flex;
        gap: 0.5rem;
      }
    }
  }
  .courses {
    margin-top: 1rem;
    .course {
      margin-bottom: 1rem;
      background: white;
      border-radius: ${measurments.borderRadius}px;
      box-shadow: ${shadows[2]};
      overflow: hidden;
      display: grid;
      grid-template-columns: 160px 1fr;
      .course-card-image {
        border-radius: ${measurments.borderRadius}px;
        overflow: hidden;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
      .course-card-text-container {
        padding: 1.5rem 2.2rem 1.6rem 1.5rem;
        .published-status {
          display: flex;
          background: ${colors.danger.bgColor};
          border: 1px solid ${colors.danger.border};
          padding: 0.2rem 0.9rem;
          border-radius: 50px;
          p {
            margin-right: 0.3rem;
            color: ${colors.danger.main};
          }
          svg {
            width: 12px;
            height: 12px;
          }
          &.published {
            background: ${colors.success.bgColor};
            border: 1px solid ${colors.success.border};
            p {
              color: ${colors.success.main};
            }
          }
        }
      }
    }
  }
  ${breakPoints.tablet} {
    .courses {
      .course {
        display: block;
        .course-card-text-container {
          padding: 1rem;
        }
      }
    }
  }
`;

const Courses = () => {
  const { globalState, notify, modal, closeModal } = useOutletContext();
  const navigate = useNavigate();

  //States
  const [courses, setCourses] = useState();
  const [loading, setLoading] = useState(true);
  const [publishFilter, setPublishFilter] = useState("");

  const publishFilters = ["Publicerade", "Ej publicerade"];

  //Functions
  const getCourses = async () => {
    setLoading(true);
    const { err, data } = await fetcher({
      path: "admin-auth/courselist",
      auth: globalState.token,
    });
    if (err) {
      notify.error("Kunde inte hämta kurser");
      setLoading(false);
      return;
    }
    setCourses(data);
    setLoading(false);
  };

  const createCourseInit = () => {
    modal(<CourseForm handleSubmit={createCourse} />, "Skapa ny kurs");
  };

  const createCourse = async (formData, cb, errCb) => {
    formData.parts = [
      {
        name: "Delmoment 1",
        contents: [],
        uuid: uuid(),
      },
    ];
    const { err, data } = await fetcher({
      path: "admin-auth/courses",
      body: formData,
      method: "POST",
      auth: globalState.token,
    });
    if (err) {
      errCb();
      notify.error("Kunde inte skapa kurs");
      return;
    }
    cb();
    closeModal();
    notify.success("Kursen skapades");
    editCourse(data);
  };

  const deleteCourseInit = (course) => {
    modal(
      <DeleteConfirmation
        item={course}
        deleteKey={"name"}
        handleInvalidKey={() => notify.error("Fyll i rätt värde i fältet")}
        action={deleteCourse}
        name="kursen"
        instruction="kursens namn"
      />,
      `Radera kursen?`
    );
  };

  const deleteCourse = async (course, cb, errCb) => {
    const { err } = await fetcher({
      path: `admin-auth/courses/${course._id}`,
      method: "DELETE",
      auth: globalState.token,
    });
    if (err) {
      notify.error("Kunde inte radera kurs");
      errCb();
      return;
    }
    notify.success("Kursen raderades");
    closeModal();
    cb();
    getCourses();
  };

  const moveCourse = async (body) => {
    setLoading(true);
    const { err } = await fetcher({
      path: "admin-auth/courselist",
      body,
      method: "POST",
      auth: globalState.token,
    });
    if (err) {
      notify.error("Kunde inte flytta kurs");
      setLoading(false);
      return;
    }
    notify.success("Kursen flyttades");
    getCourses();
  };

  const editCourse = (course) => {
    navigate(`/course/edit/${course._id}`);
  };

  const clickTag = (tag) => {
    if (publishFilter === tag) return setPublishFilter("");
    setPublishFilter(tag);
  };

  const filteredCourses = useMemo(() => {
    if (!publishFilter) return courses;
    if (publishFilter === "Publicerade")
      return courses.filter((c) => c.published);
    return courses.filter((c) => !c.published);
  }, [courses, publishFilter]);

  //Effects
  useEffect(() => {
    getCourses();
    //eslint-disable-next-line
  }, []);

  return (
    <StyledCourses className="content ptop2">
      {loading ? (
        <Loader />
      ) : (
        <>
          <div className="courses-top">
            <div className="courses-top-left">
              <h2 className="m0 h2">Kurser</h2>
              <div className="tags">
                {publishFilters.map((tag) => (
                  <Tag
                    key={`tag_${tag}`}
                    tagName={tag}
                    select={() => clickTag(tag)}
                    selected={publishFilter === tag}
                  />
                ))}
              </div>
            </div>
            <IconButton iconRight="plus" onClick={createCourseInit}>
              Skapa ny
            </IconButton>
          </div>
          <div className="courses">
            {filteredCourses.map((course, i) => (
              <div className="course" key={course._id}>
                <div className="course-card-image">
                  <ResponsiveImage file={course.mainImage} />
                </div>
                <div className="course-card-text-container">
                  <div className="flex ac jcsb">
                    <p className="h3 m0">{course.name}</p>
                    <div
                      className={`published-status ${
                        course.published ? "published" : ""
                      }`}
                    >
                      <p className="m0 b3">
                        {course.published ? "Publicerad" : "Ej publicerad"}
                      </p>
                      <SVG
                        name="upload-cloud"
                        stroke={
                          course.published
                            ? colors.success.main
                            : colors.danger.main
                        }
                      />
                    </div>
                  </div>
                  <div className="flex mtop2 ac jce gap05">
                    {i !== 0 && (
                      <div
                        title="Flytta kurs uppåt"
                        className="pointer"
                        onClick={() => moveCourse({ isUp: true, course })}
                      >
                        <SVG
                          stroke={colors.neutral[70]}
                          name="arrow-up-square"
                        />
                      </div>
                    )}
                    {i !== courses.length - 1 && (
                      <div
                        title="Flytta kurs nedåt"
                        className="pointer"
                        onClick={() => moveCourse({ course })}
                      >
                        <SVG
                          stroke={colors.neutral[70]}
                          name="arrow-down-square"
                        />
                      </div>
                    )}
                    <Link to={`/course/edit/${course._id}`}>
                      <Button>Redigera</Button>
                    </Link>
                    <Button
                      btnType="danger"
                      onClick={() => deleteCourseInit(course)}
                    >
                      Radera
                    </Button>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </>
      )}
    </StyledCourses>
  );
};

export default Courses;
