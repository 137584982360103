import styled from "styled-components";
import ResponsiveImage from "../contentComponents/view/ResponsiveImage";
import SVG from "../../sharedUI/SVG";
import { breakPoints, colors, measurments, shadows } from "../../utils/config";
import Button from "../../sharedUI/Button";
import Tag from "../../sharedUI/Tag";

const StyledGuideEntryCard = styled.div`
  margin-bottom: 1rem;
  background: white;
  border-radius: ${measurments.borderRadius}px;
  box-shadow: ${shadows[2]};
  overflow: hidden;
  display: grid;
  grid-template-columns: 140px 1fr;
  .entry-image {
    border-radius: ${measurments.borderRadius}px;
    overflow: hidden;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  .entry-text-container {
    padding: 1.5rem 2.2rem 1rem 1.5rem;
    .published-status {
      align-self: flex-start;
      display: flex;
      background: ${colors.danger.bgColor};
      border: 1px solid ${colors.danger.border};
      padding: 0.2rem 0.9rem;
      border-radius: 50px;
      p {
        margin-right: 0.3rem;
        color: ${colors.danger.main};
      }
      svg {
        width: 12px;
        height: 12px;
      }
      &.published {
        background: ${colors.success.bgColor};
        border: 1px solid ${colors.success.border};
        p {
          color: ${colors.success.main};
        }
      }
    }
  }
  ${breakPoints.tablet} {
    display: block;
    .entry-text-container {
      padding: 1rem;
    }
  }
`;

const GuideEntryCard = ({ entry, deleteInit, editInit, tags, categories }) => {
  const getTagName = (tagId) => {
    if (!tags) return "";
    const tag = tags.find((t) => t._id === tagId);
    return tag ? tag.text : "";
  };

  const getCategoryName = (categoryId) => {
    if (!categories) return "";
    const category = categories.find((c) => c._id === categoryId);
    return category ? category.text : "";
  };
  return (
    <StyledGuideEntryCard>
      <div className="entry-image">
        <ResponsiveImage file={entry.image} />
      </div>
      <div className="entry-text-container">
        <div className="flex ac jcsb">
          <div>
            <p className="h3 m0">{entry.title}</p>
            <div className="mtop05 flex gap05 wrap">
              {(entry.categoryModels || []).map((tag) => (
                <Tag
                  key={`${entry._id}_tag_${tag}`}
                  tagName={getCategoryName(tag)}
                  bgColor={colors.primary.main}
                  size="small"
                />
              ))}
              {(entry.tagModels || []).map((tag) => (
                <Tag
                  key={`${entry._id}_tag_${tag}`}
                  tagName={getTagName(tag)}
                  bgColor={colors.neutral[40]}
                  size="small"
                />
              ))}
            </div>
          </div>
          <div
            className={`published-status ${entry.published ? "published" : ""}`}
          >
            <p className="m0 b3">
              {entry.published ? "Publicerad" : "Ej publicerad"}
            </p>
            <SVG
              name="upload-cloud"
              stroke={
                entry.published ? colors.success.main : colors.danger.main
              }
            />
          </div>
        </div>
        <div className="flex mtop2 ac jce gap05">
          <Button onClick={() => editInit(entry)}>Redigera</Button>
          <Button onClick={() => deleteInit(entry)} btnType="danger">
            Radera
          </Button>
        </div>
      </div>
    </StyledGuideEntryCard>
  );
};

export default GuideEntryCard;
