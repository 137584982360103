import { useEffect, useState } from "react";
import { Link, useOutletContext, useParams } from "react-router-dom";
import styled from "styled-components";
import MainContent from "../../components/courseEdit/MainContent";
import Toolbar from "../../components/courseEdit/Toolbar";
import FullScreenLoader from "../../sharedUI/FullScreenLoader";
import { breakPoints, colors, measurments } from "../../utils/config";
import { fetcher } from "../../utils/fetcher";
import sanitize from "sanitize-html";
import IconButton from "../../sharedUI/IconButton";

const StyledCourseEdit = styled.div`
  padding-bottom: 20rem;
  .editor-top {
    display: flex;
    justify-content: space-between;
    margin-bottom: 1rem;
    .editor-top-left {
      padding-bottom: 1rem;
    }
    .editor-top-right {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      justify-content: flex-end;
      .status {
        margin-bottom: 0.5rem;
        color: ${colors.primary.hover};
      }
    }
    .editor-top-right-bottom {
      display: flex;
      button {
        margin-left: 0.5rem;
        &.save-button {
          background: ${colors.primary.main};
          &:hover {
            background: ${colors.neutral[80]};
          }
          &:disabled {
            background: ${colors.neutral[20]};
            &:hover {
              background: ${colors.neutral[20]};
            }
            cursor: initial;
          }
        }
      }
    }
  }
  .editor-content {
    display: grid;
    align-items: flex-start;
    grid-template-columns: 1fr ${measurments.toolbarWidth}px;
  }
  ${breakPoints.tablet} {
    display: none;
  }
`;

const StyledCourseEditMobile = styled.div`
  display: none;
  ${breakPoints.tablet} {
    display: flex;
    flex-direction: column;
    padding-top: 4rem;
    align-items: center;
    text-align: center;
  }
`;

const CourseEdit = () => {
  const { id } = useParams();
  const { globalState, notify } = useOutletContext();

  //States
  const [courseImmutable, setCourseImmutable] = useState();
  const [course, setCourse] = useState();
  const [loading, setLoading] = useState(true);
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);

  //Functions
  const getCourse = async () => {
    const { err, data } = await fetcher({
      path: `admin-auth/courses/${id}`,
      auth: globalState.token,
    });
    if (err) {
      setLoading(false);
      notify.error("Kunde inte hämta kursen");
      return;
    }
    setCourse(JSON.parse(JSON.stringify(data)));
    setCourseImmutable(JSON.parse(JSON.stringify(data)));
    setLoading(false);
  };

  const sanitizeContent = (course) => {
    const sanitizedParts = course.parts.map((part) => {
      const sanitizedContents = part.contents.map((content) => {
        if (content.type === "richtext")
          return {
            ...content,
            data: {
              ...content.data,
              html: sanitize(content.data.html),
            },
          };
        else return content;
      });
      return {
        ...part,
        contents: sanitizedContents,
      };
    });
    return {
      ...course,
      parts: sanitizedParts,
    };
  };

  const updateCourse = async () => {
    setLoading(true);
    const sanitizedCourse = sanitizeContent(course);
    const { err } = await fetcher({
      path: `admin-auth/courses/${id}`,
      method: "POST",
      body: sanitizedCourse,
      auth: globalState.token,
    });
    if (err) {
      setLoading(false);
      notify.error("Kunde inte spara kurs");
      return;
    }
    notify.success("Kursen sparades");
    getCourse();
  };

  //Effects
  useEffect(() => {
    const value = JSON.stringify(course) !== JSON.stringify(courseImmutable);
    setHasUnsavedChanges(value);
  }, [course, courseImmutable]);

  useEffect(() => {
    if (id) getCourse();
    // eslint-disable-next-line
  }, [id]);

  if (loading) return <FullScreenLoader />;
  if (!course && !loading) return <p>Kunde inte hämta kursen</p>;
  return (
    <>
      <StyledCourseEdit className="content ptop2">
        <div className="editor-top">
          <div className="editor-top-left">
            <Link to="/courses">
              <IconButton
                iconLeft="chevron-left"
                stroke={colors.neutral[90]}
                trans
              >
                Tillbaka
              </IconButton>
            </Link>

            <h1 className="m0 mtop05">Redigera kurs</h1>
          </div>
          <div className="editor-top-right">
            <p className="status m0 b2">
              Status: {hasUnsavedChanges ? "Ej sparad" : "Sparad"},{" "}
              {courseImmutable.published ? "publicerad" : "ej publicerad"}
            </p>
            <div className="editor-top-right-bottom">
              <IconButton
                iconRight="save"
                stroke={loading ? colors.neutral[50] : colors.neutral[100]}
                className="save-button"
                disabled={loading || !hasUnsavedChanges}
                onClick={updateCourse}
              >
                {loading ? "Sparar" : "Spara"}
              </IconButton>
            </div>
          </div>
        </div>
        <div className="editor-content">
          <MainContent course={course} setCourse={setCourse} />
          <Toolbar
            course={course}
            setCourse={setCourse}
            save={updateCourse}
            hasUnsavedChanges={hasUnsavedChanges}
          />
        </div>
      </StyledCourseEdit>
      <StyledCourseEditMobile>
        <p>Denna vy är inte tillgänglig mobilt.</p>
        <Link to="/courses">
          <IconButton trans iconLeft="chevron-left">
            Tillbaka
          </IconButton>
        </Link>
      </StyledCourseEditMobile>
    </>
  );
};

export default CourseEdit;
