import { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import SVG from "../../sharedUI/SVG";
import { breakPoints } from "../../utils/config";

const StyledModal = styled.div`
  background: rgba(0, 0, 0, 0.25);
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  inset: 0;
  opacity: 0;
  pointer-events: none;
  transition: 200ms ease-in-out;
  ${({ _z }) => _z && "z-index: " + _z + ";"}
  .modal-inner {
    min-width: 500px;
    max-width: calc(100vw - 5rem);
    transition: 200ms ease-in-out;
    margin-top: 100px;
    background: white;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    .modal-content {
      padding: 0 46px;
      padding-top: 1.5rem;
      padding-bottom: 1.5rem;
      max-height: 80vh;
      overflow-y: auto;
    }
    .modal-top {
      padding: 18px 46px;
      box-shadow: 0 8px 12px rgba(0, 0, 0, 0.1);
      transition: 400ms ease-in-out;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .modal-close {
        cursor: pointer;
        position: relative;
        left: 1.5rem;
      }
    }
  }
  &.scrolled-top {
    .modal-top {
      box-shadow: none;
    }
  }
  &.active {
    opacity: 1;
    pointer-events: initial;
    top: 0;
    .modal-inner {
      margin-top: 0;
    }
  }
  ${breakPoints.tablet} {
    .modal-inner {
      width: calc(100vw - 1rem);
      max-width: revert;
      min-width: revert;
      padding: 1rem 1.5rem 2rem 1.5rem;
      .modal-top {
        .modal-close {
          left: 0.8rem;
        }
      }
    }
  }
`;

const Modal = ({ modal, closeModal, zIndex }) => {
  const modalRef = useRef(null);
  const scrollContainerRef = useRef(null);

  const [isScrolledToTop, setIsScrolledToTop] = useState(true);
  const [isScrolledToBottom, setIsScrolledToBottom] = useState(false);

  useEffect(() => {
    const handleClickOutside = (e) => {
      const isOutsideModal =
        modalRef.current && !modalRef.current.contains(e.target);
      const timyMceAbsoluteContainer = document.querySelector(
        ".tox.tox-silver-sink.tox-tinymce-aux"
      );
      const isTinyMceAbsoluteContainer =
        timyMceAbsoluteContainer && timyMceAbsoluteContainer.contains(e.target);
      if (isOutsideModal && !isTinyMceAbsoluteContainer) {
        closeModal();
      }
    };
    if (modal.active) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
    //eslint-disable-next-line
  }, [modalRef, modal.active]);

  useEffect(() => {
    const ref = scrollContainerRef.current;
    const handleScroll = (e) => {
      const { scrollTop, scrollHeight, offsetHeight } = e.target;
      if (scrollTop === 0) setIsScrolledToTop(true);
      else setIsScrolledToTop(false);
      if (scrollTop === scrollHeight - offsetHeight)
        setIsScrolledToBottom(true);
      else setIsScrolledToBottom(false);
    };
    if (ref) {
      ref.addEventListener("scroll", handleScroll);
    }
    return () => {
      if (ref) {
        ref.removeEventListener("scroll", handleScroll);
      }
    };
  }, [scrollContainerRef]);

  return (
    <StyledModal
      className={`${modal.active ? "active" : " "} ${
        isScrolledToBottom ? "scrolled-bottom" : ""
      } ${isScrolledToTop ? "scrolled-top" : ""}`}
      _z={zIndex}
    >
      <div
        className={`modal-inner ${modal.className ? modal.className : ""}`}
        ref={modalRef}
      >
        <div className="modal-top">
          {!!modal.header ? <h2 className="m0">{modal.header}</h2> : <div />}
          <div className="modal-close" onClick={closeModal}>
            <SVG name="x" stroke={"black"} />
          </div>
        </div>
        <div ref={scrollContainerRef} className="modal-content">
          {modal.content}
        </div>
        {!!modal.footerContent && (
          <div className="modal-footer">{modal.footerContent}</div>
        )}
      </div>
    </StyledModal>
  );
};

export default Modal;
