import React, { useEffect, useState } from "react";
import { Link, useOutletContext } from "react-router-dom";
import styled from "styled-components";
import { Loader } from "../../sharedUI/FullScreenLoader";
import IconButton from "../../sharedUI/IconButton";
import { fetcher } from "../../utils/fetcher";
import GuideTagForm from "../../components/forms/GuideTagForm";
import DeleteConfirmation from "../../components/forms/DeleteConfirmation";
import TagCard from "../../components/cards/TagCard";

const StyledGuideTagsPage = styled.div`
  .events-top {
    display: flex;
    justify-content: space-between;
    &-right {
      display: flex;
      align-items: center;
      gap: 0.5rem;
      .search-input {
        margin: 0;
      }
    }
  }
  .entries {
    margin-top: 1rem;
  }
`;

const GuideTagsPage = () => {
  const outlet = useOutletContext();
  const { globalState, notify, modal, closeModal } = outlet;

  //State
  const [guideTags, setGuideTags] = useState([]);
  const [loading, setLoading] = useState(true);

  //Functions

  const getTags = async () => {
    const { err, data } = await fetcher({
      path: "guideTags",
      auth: globalState.token,
    });
    if (err) {
      notify.error("Kunde inte hämta taggar");
    } else {
      setGuideTags(data);
    }
  };

  const getData = async () => {
    setLoading(true);
    const promises = [getTags()];
    await Promise.all(promises);
    setLoading(false);
  };

  const createEntry = async (formData, cb, errCb) => {
    const { err } = await fetcher({
      path: "guideTags",
      body: formData,
      method: "POST",
      auth: globalState.token,
    });
    if (err) {
      errCb();
      notify.error("Kunde inte skapa tagg");
      return;
    }
    cb();
    closeModal();
    getData();
  };

  const editEntry = async (formData, cb, errCb) => {
    const { err } = await fetcher({
      path: `guideTags/${formData._id}`,
      body: formData,
      method: "POST",
      auth: globalState.token,
    });
    if (err) {
      errCb();
      notify.error("Kunde inte uppdatera tagg");
      return;
    }
    cb();
    closeModal();
    getData();
  };

  const deleteEntry = async (entry, cb, errCb) => {
    const { err } = await fetcher({
      path: `guideTags/${entry._id}`,
      method: "DELETE",
      auth: globalState.token,
    });
    if (err) {
      errCb();
      notify.error("Kunde inte radera tagg");
      return;
    }
    cb();
    closeModal();
    getData();
  };

  const deleteEntryInit = (entry) => {
    modal(
      <DeleteConfirmation
        item={entry}
        deleteKey={"text"}
        handleInvalidKey={() => notify.error("Fyll i rätt värde i fältet")}
        action={deleteEntry}
        name="taggen"
        instruction="taggens namn"
      />,
      "Radera tagg"
    );
  };

  const editEntryInit = (entry) => {
    modal(
      <GuideTagForm data={entry} handleSubmit={editEntry} outlet={outlet} />,
      "Redigera tagg"
    );
  };

  const createEntryInit = () => {
    modal(
      <GuideTagForm handleSubmit={createEntry} outlet={outlet} />,
      "Skapa ny tagg"
    );
  };

  useEffect(() => {
    getData();
    // eslint-disable-next-line
  }, []);

  return (
    <StyledGuideTagsPage className="content ptop2">
      {loading ? (
        <Loader />
      ) : (
        <>
          <Link to="/guide">
            <IconButton trans iconLeft="chevron-left" className="mbottom1">
              Tillbaka till tränarguide
            </IconButton>
          </Link>
          <div className="events-top">
            <h2 className="m0 h2">Tränarguiden taggar</h2>
            <div className="events-top-right">
              <IconButton iconRight="plus" onClick={createEntryInit}>
                Skapa ny
              </IconButton>
            </div>
          </div>
          <div className="entries">
            {guideTags.length ? (
              guideTags.map((entry) => (
                <TagCard
                  key={entry._id}
                  entry={entry}
                  deleteInit={deleteEntryInit}
                  editInit={editEntryInit}
                />
              ))
            ) : (
              <p>Inga taggar hittades</p>
            )}
          </div>
        </>
      )}
    </StyledGuideTagsPage>
  );
};

export default GuideTagsPage;
