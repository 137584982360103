import styled from "styled-components";
import Input from "./Input";
import { useMemo } from "react";
import { youtubeUrl } from "../utils/youtubeUrl";

const StyledYoutubeInput = styled.div`
  margin-bottom: 1rem;
  .iframe-container {
    position: relative;
    width: 100%;
    padding-bottom: 56.25%;
    height: 0;
    margin-bottom: 2rem;
  }
  .iframe-container iframe {
    border: 0px;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`;

const YoutubeInput = ({ name, onChange, value, label }) => {
  const embedLink = useMemo(() => {
    const id = youtubeUrl(value);
    if (!id) return null;
    return `https://www.youtube.com/embed/${id}`;
  }, [value]);
  const handleChange = (e) => {
    onChange(e);
  };

  return (
    <StyledYoutubeInput>
      <Input
        label={label}
        placeholder="Klistra in Youtube-länk"
        name={name}
        value={value}
        onChange={handleChange}
      />
      {!embedLink && !!value && <p className="m0 danger">Felaktig länk</p>}
      {!!embedLink && (
        <div className="iframe-container">
          <iframe
            width="1920"
            height="1080"
            src={embedLink}
            title="Video player"
            allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          />
        </div>
      )}
    </StyledYoutubeInput>
  );
};

export default YoutubeInput;
