import styled from "styled-components";
import { colors, measurments } from "../utils/config";
import SVG from "../sharedUI/SVG";

const StyledFormTagCard = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid ${colors.neutral[50]};
  padding: 0.5rem;
  border-radius: ${measurments.borderRadius}px;
  margin-bottom: 0.5rem;
  p {
    margin: 0;
  }
  .buttons {
    display: flex;
    align-items: center;
    .button {
      cursor: pointer;
      svg {
        width: 1.5rem;
        height: 1.5rem;
      }
    }
  }
`;

const FormTagCard = ({
  getTagName,
  tagId,
  moveTagUp,
  moveTagDown,
  removeTag,
}) => {
  return (
    <StyledFormTagCard>
      <p>{getTagName(tagId)}</p>
      <div className="buttons">
        <div className="button" role="button" onClick={moveTagUp}>
          <SVG name="arrow-up-square" />
        </div>
        <div className="button" role="button" onClick={moveTagDown}>
          <SVG name="arrow-down-square" />
        </div>
        <div className="button" role="button" onClick={removeTag}>
          <SVG name="x" />
        </div>
      </div>
    </StyledFormTagCard>
  );
};

export default FormTagCard;
