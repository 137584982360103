import styled from "styled-components";
import MediaLibrary from "../../components/ui/MediaLibrary";

const StyledMedia = styled.div`
  padding-bottom: 20rem;
`;

const Media = () => {
  return (
    <StyledMedia className="content">
      <MediaLibrary />
    </StyledMedia>
  );
};

export default Media;
