import { useMemo, useState } from "react";
import styled from "styled-components";
import ResponsiveImage from "../components/contentComponents/view/ResponsiveImage";
import Button from "./Button";
import Modal from "../components/ui/Modal";
import MediaLibrary from "../components/ui/MediaLibrary";
import { colors } from "../utils/config";
import DocumentCard from "./DocumentCard";

const StyledFileInput = styled.div`
  margin-bottom: 1rem;
  .library-container {
    max-width: 800px;
    width: 100%;
    height: 100%;
  }
  .library-modal-wrapper {
    background: ${colors.neutral[30]};
  }
`;

const FileInput = ({
  type = "image",
  value,
  onChange,
  label,
  name,
  outlet,
}) => {
  if (!outlet) console.error("FileInput.js requires outlet to function");

  const [libraryOpen, setLibraryOpen] = useState(false);

  const handleSelect = (value) => {
    const event = {
      target: {
        name,
        value,
      },
    };
    onChange(event);
    setLibraryOpen(false);
  };

  const removeFile = () => {
    const event = {
      target: {
        name,
        value: null,
      },
    };
    onChange(event);
  };

  const itemTerm = useMemo(() => {
    if (type === "image") return "bild";
    if (type === "pdf") return "dokument";
    return "fil";
  }, [type]);

  return (
    <StyledFileInput>
      {!!label && <span className="b2">{label}</span>}
      {type === "image" && <ResponsiveImage file={value} />}
      {type === "pdf" && <DocumentCard file={value} />}
      <div className="flex jce gap05">
        <Button
          btnType="neutral"
          className="mtop05"
          onClick={() => setLibraryOpen(true)}
        >
          {value ? `Byt ${itemTerm}` : `Välj ${itemTerm}`}
        </Button>
        {!!value && (
          <Button btnType="danger" className="mtop05" onClick={removeFile}>
            Ta bort {itemTerm}
          </Button>
        )}
      </div>
      <Modal
        zIndex={10}
        closeModal={() => setLibraryOpen(false)}
        modal={{
          header: `Välj ${itemTerm}`,
          className: "library-modal-wrapper",
          active: libraryOpen,
          content: (
            <div className="library-container">
              <MediaLibrary
                handleSelect={handleSelect}
                type={type}
                outlet={outlet}
              />
            </div>
          ),
        }}
      />
    </StyledFileInput>
  );
};

export default FileInput;
