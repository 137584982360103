import { useEffect, useState } from "react";
import styled from "styled-components";
import Button from "../../sharedUI/Button";
import Input from "../../sharedUI/Input";

const StyledFileForm = styled.div``;

const FileForm = ({ handleSubmit, data, header }) => {
  const initialFile = {
    name: "",
  };

  const [formData, setFormData] = useState(initialFile);
  const [loading, setLoading] = useState(false);

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  useEffect(() => {
    if (data) setFormData(data);
  }, [data]);

  return (
    <StyledFileForm>
      <form
        onSubmit={(e) => {
          setLoading(true);
          e.preventDefault();
          handleSubmit(
            formData,
            () => {
              setFormData(initialFile);
              setLoading(false);
            },
            () => {
              setLoading(false);
            }
          );
        }}
      >
        <Input
          label="Filnamn"
          type="text"
          value={formData.name}
          onChange={handleChange}
          name="name"
          placeholder="Filnamn"
          required
          disabled={loading}
        />
        <div className="flex jce">
          <Button type="submit" disabled={loading}>
            {loading ? "Sparar..." : "Spara"}
          </Button>
        </div>
      </form>
    </StyledFileForm>
  );
};

export default FileForm;
