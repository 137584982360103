import React, { useState } from "react";
import { Route, BrowserRouter, Routes } from "react-router-dom";
import Layout from "./components/wrappers/Layout";
import ProtectedRoute from "./components/wrappers/ProtectedRoute";
import Login from "./routes/Login";
import Dashboard from "./routes/protected/Dashboard";
import Register from "./routes/Register";
import { fetcher } from "./utils/fetcher";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Users from "./routes/protected/Users";
import Courses from "./routes/protected/Courses";
import Media from "./routes/protected/Media";
import CourseEdit from "./routes/protected/CourseEdit";
import Profile from "./routes/protected/Profile";
import Guide from "./routes/protected/Guide";
import Plans from "./routes/protected/Plans";
import EventPlans from "./routes/protected/EventPlans";
import DocumentsPage from "./routes/protected/Documents";
import GuideCategoriesPage from "./routes/protected/GuideCategories";
import GuideTagsPage from "./routes/protected/GuideTags";

const App = () => {
  const [globalState, setGlobalState] = useState({
    user: null,
    token: null,
    appLoading: true,
    modal: {
      active: false,
      content: <div />,
      footerContent: null,
      header: "",
    },
  });

  const toastOptions = {
    position: "top-right",
    autoClose: true,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: false,
    progress: undefined,
  };

  const notify = {
    info: (m) => toast.info(m, toastOptions),
    error: (m) => toast.error(m, toastOptions),
    success: (m) => toast.success(m, toastOptions),
  };

  const login = async (formData, cb, errCb) => {
    const { err, data } = await fetcher({
      path: "admin-no-auth/login",
      method: "POST",
      body: formData,
    });
    if (err) return errCb("Fel e-post eller lösenord");
    setGlobalState({
      ...globalState,
      token: data.token,
      user: data.user,
    });
    localStorage.setItem("bg-kurswebb-admin-token", data.token);
    cb(data);
  };

  const modal = (content, header, footerContent = null) => {
    setGlobalState({
      ...globalState,
      modal: {
        active: true,
        content,
        header,
        footerContent,
      },
    });
  };

  const closeModal = () => {
    setGlobalState({
      ...globalState,
      modal: {
        ...globalState.modal,
        active: false,
      },
    });
  };

  return (
    <BrowserRouter>
      <Layout modal={globalState.modal} closeModal={closeModal}>
        <Routes>
          <Route
            path="login"
            element={
              <Login
                login={login}
                globalState={globalState}
                setGlobalState={setGlobalState}
                notify={notify}
              />
            }
          />
          <Route
            path="register"
            element={
              <Register
                login={login}
                globalState={globalState}
                setGlobalState={setGlobalState}
                notify={notify}
              />
            }
          />
          <Route
            element={
              <ProtectedRoute
                globalState={globalState}
                setGlobalState={setGlobalState}
                notify={notify}
                modal={modal}
                closeModal={closeModal}
              />
            }
          >
            <Route index element={<Dashboard />} />
            <Route path="guide" element={<Guide />} />
            <Route path="guide/categories" element={<GuideCategoriesPage />} />
            <Route path="guide/tags" element={<GuideTagsPage />} />
            <Route path="plans" element={<Plans />} />
            <Route path="events" element={<EventPlans />} />
            <Route path="documents" element={<DocumentsPage />} />
            <Route path="users" element={<Users />} />
            <Route path="courses" element={<Courses />} />
            <Route path="media" element={<Media />} />
            <Route path="profile" element={<Profile />} />
            <Route path="course/edit/:id" element={<CourseEdit />} />
          </Route>
          <Route path="*" element={<div>404</div>} />
        </Routes>
      </Layout>
    </BrowserRouter>
  );
};

export default App;
