import React, { useEffect, useMemo, useState } from "react";
import { useOutletContext } from "react-router-dom";
import styled from "styled-components";
import { Loader } from "../../sharedUI/FullScreenLoader";
import IconButton from "../../sharedUI/IconButton";
import { fetcher } from "../../utils/fetcher";
import PlanForm from "../../components/forms/PlanForm";
import DeleteConfirmation from "../../components/forms/DeleteConfirmation";
import PlanCard from "../../components/cards/PlanCard";
import Input from "../../sharedUI/Input";

const StyledPlans = styled.div`
  .plans-top {
    display: flex;
    justify-content: space-between;
    &-right {
      display: flex;
      align-items: center;
      gap: 0.5rem;
      .search-input {
        margin: 0;
      }
    }
  }
  .entries {
    margin-top: 1rem;
  }
`;

const Plans = () => {
  const outlet = useOutletContext();
  const { globalState, notify, modal, closeModal } = outlet;

  //State
  const [plans, setPlans] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchString, setSearchString] = useState("");

  //Functions
  const getEntries = async () => {
    setLoading(true);
    const { err, data } = await fetcher({
      path: "plan/admin",
      auth: globalState.token,
    });
    if (err) {
      notify.error("Kunde inte hämta träningsplaneringar");
    } else {
      setPlans(data);
    }
    setLoading(false);
  };

  const createEntry = async (formData, cb, errCb) => {
    const { err } = await fetcher({
      path: "plan",
      body: formData,
      method: "POST",
      auth: globalState.token,
    });
    if (err) {
      errCb();
      notify.error("Kunde inte skapa träningsplanering");
      return;
    }
    cb();
    closeModal();
    getEntries();
  };

  const editEntry = async (formData, cb, errCb) => {
    const { err } = await fetcher({
      path: `plan/${formData._id}`,
      body: formData,
      method: "POST",
      auth: globalState.token,
    });
    if (err) {
      errCb();
      notify.error("Kunde inte uppdatera träningsplanering");
      return;
    }
    cb();
    closeModal();
    getEntries();
  };

  const deleteEntry = async (entry, cb, errCb) => {
    const { err } = await fetcher({
      path: `plan/${entry._id}`,
      method: "DELETE",
      auth: globalState.token,
    });
    if (err) {
      errCb();
      notify.error("Kunde inte radera träningsplanering");
      return;
    }
    cb();
    closeModal();
    getEntries();
  };

  const deleteEntryInit = (entry) => {
    modal(
      <DeleteConfirmation
        item={entry}
        deleteKey={"title"}
        handleInvalidKey={() => notify.error("Fyll i rätt värde i fältet")}
        action={deleteEntry}
        name="träningsplaneringen"
        instruction="träningsplaneringens titel"
      />,
      "Radera träningsplanering"
    );
  };

  const editEntryInit = (entry) => {
    modal(
      <PlanForm data={entry} handleSubmit={editEntry} outlet={outlet} />,
      "Redigera träningsplanering"
    );
  };

  const createEntryInit = () => {
    modal(
      <PlanForm handleSubmit={createEntry} outlet={outlet} />,
      "Skapa ny träningsplanering"
    );
  };

  const sortedPlans = useMemo(() => {
    return plans.sort((a, b) => a.sort - b.sort);
  }, [plans]);

  const filteredPlans = useMemo(() => {
    if (!searchString) return sortedPlans;
    const s = searchString.toLocaleLowerCase();
    let matches = [];
    sortedPlans.forEach((entry) => {
      const search = entry.title.toLowerCase().search(s);
      if (search !== -1) matches.push(entry);
    });
    return matches;
  }, [searchString, sortedPlans]);

  const sortPlan = async ({ plan, isUp }) => {
    const updatedPlans = [...sortedPlans];
    const currentIndex = updatedPlans.findIndex(
      (entry) => entry._id === plan._id
    );
    if (currentIndex === -1) return;
    const [moved] = updatedPlans.splice(currentIndex, 1);
    const newIndex = isUp ? currentIndex - 1 : currentIndex + 1;
    updatedPlans.splice(newIndex, 0, moved);
    const withSort = updatedPlans.map((plan, i) => ({
      ...plan,
      sort: i,
    }));

    setLoading(true);
    const { err } = await fetcher({
      path: `plan/update-sorting`,
      method: "POST",
      auth: globalState.token,
      body: withSort,
    });
    if (err) {
      setLoading(false);
      notify.error("Kunde inte uppdatera sortering");
      return;
    }
    notify.success("Sortering uppdaterad");
    getEntries();
  };

  useEffect(() => {
    getEntries();
    // eslint-disable-next-line
  }, []);

  return (
    <StyledPlans className="content ptop2">
      {loading ? (
        <Loader />
      ) : (
        <>
          <div className="plans-top">
            <h2 className="m0 h2">Träningsplaneringar</h2>
            <div className="plans-top-right">
              <Input
                value={searchString}
                onChange={(e) => setSearchString(e.target.value)}
                type="text"
                placeholder="Sök planering"
                className="search-input"
              />
              <IconButton iconRight="plus" onClick={createEntryInit}>
                Skapa ny
              </IconButton>
            </div>
          </div>
          <div className="entries">
            {filteredPlans.length ? (
              filteredPlans.map((entry, index) => (
                <PlanCard
                  length={sortedPlans.length}
                  index={index}
                  key={entry._id}
                  entry={entry}
                  deleteInit={deleteEntryInit}
                  editInit={editEntryInit}
                  sortPlan={sortPlan}
                />
              ))
            ) : (
              <p>Inga träningsplaneringar hittades</p>
            )}
          </div>
        </>
      )}
    </StyledPlans>
  );
};

export default Plans;
