import { useState } from "react";
import styled from "styled-components";
import Button from "../../sharedUI/Button";
import SVG from "../../sharedUI/SVG";
import { colors } from "../../utils/config";
import { parseDate } from "../../utils/parseDate";
import MediaThumbnail from "../ui/MediaThumbnail";

const StyledMediaItem = styled.div`
  background: white;
  transition: 200ms ease-in-out;
  img {
    width: 80px;
    height: 60px;
    object-fit: cover;
  }
  p {
    font-size: 0.9rem;
  }
  .filename,
  .used-in {
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .list-item-expanded {
    max-height: 0;
    transition: 200ms ease-in-out;
    padding-top: 0;
    padding-bottom: 0;
    padding-left: 1rem;
    padding-right: 1rem;
    overflow: hidden;
  }
  &.expanded {
    background: ${colors.neutral[30]};
    .list-item-expanded {
      max-height: 500px;
      padding-top: 1rem;
      padding-bottom: 1rem;
    }
  }
`;

const MediaItem = ({
  file,
  deleteFile,
  updateFile,
  spotlight,
  handleSelect,
}) => {
  const [expanded, setExpanded] = useState(false);

  return (
    <StyledMediaItem className={`${expanded ? "expanded" : ""}`}>
      <div className={`list-grid`}>
        <MediaThumbnail file={file} onClick={spotlight} />
        <p className="filename" title={file.name}>
          {file.name}
        </p>
        <p className="used-in" title={file.usedIn}>
          {file.usedIn || "-"}
        </p>
        <p className="type">{file.extension.toUpperCase(9)}</p>
        <p className="upload-date">{parseDate(file.uploadedAt)}</p>
        {handleSelect ? (
          <Button onClick={() => handleSelect(file)}>Välj</Button>
        ) : (
          <div onClick={() => setExpanded(!expanded)}>
            <SVG
              name={expanded ? "chevron-up" : "chevron-down"}
              stroke={colors.neutral[80]}
            />
          </div>
        )}
      </div>
      <div className="list-item-expanded">
        <div className="mobile-additional-info">
          <p className="b1 m0">Används i</p>
          <p className="b2 m0 mbottom05">{file.usedIn || "-"}</p>
          <p className="b1 m0">Uppladdningsdatum</p>
          <p className="b2 m0 mbottom1">{parseDate(file.uploadedAt)}</p>
        </div>
        <div className="flex jce ac gap05">
          <Button btnType="neutral" onClick={() => updateFile(file)}>
            Byt namn
          </Button>
          <Button
            btnType="danger"
            disabled={!!file.usedIn}
            onClick={() => deleteFile(file)}
          >
            Radera
          </Button>
        </div>
      </div>
    </StyledMediaItem>
  );
};

export default MediaItem;
