import styled from "styled-components";
import { ToastContainer } from "react-toastify";
import Modal from "../ui/Modal";
import { colors, measurments } from "../../utils/config";

const StyledLayout = styled.div`
  background: ${colors.neutral[30]};
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  .content {
    max-width: ${measurments.contentWidth}px;
    margin-left: auto;
    margin-right: auto;
    padding-left: 1rem;
    padding-right: 1rem;
  }
  .label {
    display: block;
    margin-bottom: 0.25rem;
    color: ${colors.neutral[90]};
  }
  main {
    flex-grow: 1;
  }
  .danger {
    color: ${colors.danger.main};
  }
`;

const Layout = ({ children, modal, closeModal }) => {
  return (
    <StyledLayout>
      {children}
      <Modal modal={modal} closeModal={closeModal} />
      <ToastContainer newestOnTop />
    </StyledLayout>
  );
};

export default Layout;
