import styled from "styled-components";
import SVG from "../../../sharedUI/SVG";
import { colors } from "../../../utils/config";
import HeaderEdit from "./HeaderEdit";
import ImageEdit from "./ImageEdit";
import RichTextEdit from "./RichTextEdit";
import VideoEdit from "./VideoEdit";
import YoutubeEdit from "./YoutubeEdit";
import DocumentEdit from "./DocumentEdit";

const StyledEditableContentWrapper = styled.div`
  border-left: 1px solid ${colors.neutral[50]};
  border-top: 1px solid ${colors.neutral[50]};
  padding: 0.5rem 0.5rem 0rem 0.5rem;
  margin-bottom: 1.5rem;
  .editable-content-headers {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: ${colors.primary.hover};
    border-bottom: 1px solid ${colors.neutral[40]};
    padding-bottom: 0.5rem;
    margin-bottom: 0.5rem;
    .editable-content-actions {
      display: flex;
      align-items: center;
      .svg-button {
        cursor: pointer;
        svg {
          width: 18px;
          height: 18px;
          margin-right: 0.2rem;
        }
      }
    }
  }
`;

const EditableContentWrapper = ({
  children,
  deleteContent,
  moveContentUp,
  moveContentDown,
  content,
}) => {
  return (
    <StyledEditableContentWrapper>
      <div className="editable-content-headers">
        <h4 className="m0">{content.name}</h4>
        <div className="editable-content-actions">
          <div className="svg-button" onClick={moveContentDown}>
            <SVG name="arrow-down-square" stroke={colors.primary.hover} />
          </div>
          <div className="svg-button" onClick={moveContentUp}>
            <SVG name="arrow-up-square" stroke={colors.primary.hover} />
          </div>
          <div className="svg-button" onClick={deleteContent}>
            <SVG name="trash" stroke={colors.primary.hover} />
          </div>
        </div>
      </div>
      {children}
    </StyledEditableContentWrapper>
  );
};

const EditableContent = (props) => {
  const { content, setContentData, outlet } = props;
  const { type } = content;
  switch (type) {
    case "richtext":
      return (
        <EditableContentWrapper {...props}>
          <RichTextEdit content={content} setContentData={setContentData} />
        </EditableContentWrapper>
      );
    case "image":
      return (
        <EditableContentWrapper {...props}>
          <ImageEdit
            content={content}
            setContentData={setContentData}
            outlet={outlet}
          />
        </EditableContentWrapper>
      );
    case "document":
      return (
        <EditableContentWrapper {...props}>
          <DocumentEdit
            content={content}
            setContentData={setContentData}
            outlet={outlet}
          />
        </EditableContentWrapper>
      );
    case "ytvideo":
      return (
        <EditableContentWrapper {...props}>
          <YoutubeEdit content={content} setContentData={setContentData} />
        </EditableContentWrapper>
      );
    case "video":
      return (
        <EditableContentWrapper {...props}>
          <VideoEdit
            content={content}
            setContentData={setContentData}
            outlet={outlet}
          />
        </EditableContentWrapper>
      );
    case "header":
      return (
        <EditableContentWrapper {...props}>
          <HeaderEdit content={content} setContentData={setContentData} />
        </EditableContentWrapper>
      );
    default:
      return null;
  }
};

export default EditableContent;
