import { useEffect, useState } from "react";
import styled from "styled-components";
import Input from "../../sharedUI/Input";
import Button from "../../sharedUI/Button";
import Checkbox from "../../sharedUI/Checkbox";
import FileInput from "../../sharedUI/FileInput";
import RichTextInput from "../../sharedUI/RichTextInput";
import YoutubeInput from "../../sharedUI/YoutubeInput";
import Select from "../../sharedUI/Select";
import Tag from "../../sharedUI/Tag";
import { colors } from "../../utils/config";

const StyledGuideEntryForm = styled.div`
  form {
    max-width: 600px;
  }
  .categories {
    display: flex;
    gap: 0.5rem;
    flex-wrap: wrap;
    margin-bottom: 1rem;
  }
  .tags-container {
    display: flex;
    gap: 0.5rem;
    flex-wrap: wrap;
    margin-bottom: 1rem;
  }
`;

const GuideEntryForm = ({ handleSubmit, data, outlet, tags, categories }) => {
  const initialData = {
    title: "",
    image: null,
    videoUrl: "",
    tags: [],
    topCategories: [],
    categoryModels: [],
    tagModels: [],
    richTextHtml: "",
    published: false,
    featured: false,
  };

  const [formData, setFormData] = useState(initialData);
  const [loading, setLoading] = useState(false);

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const onSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    handleSubmit(
      formData,
      () => {
        setFormData(initialData);
        setLoading(false);
      },
      () => setLoading(false)
    );
  };

  const getTagName = (tagId) => {
    if (!tags) return "";
    const tag = tags.find((t) => t._id === tagId);
    return tag ? tag.text : "";
  };

  const getCategoryName = (categoryId) => {
    if (!categories) return "";
    const category = categories.find((c) => c._id === categoryId);
    return category ? category.text : "";
  };

  const addCategory = (categoryId) => {
    setFormData({
      ...formData,
      categoryModels: [...(formData.categoryModels || []), categoryId],
    });
  };

  const removeCategory = (categoryId) => {
    setFormData({
      ...formData,
      categoryModels: (formData.categoryModels || []).filter(
        (c) => c !== categoryId
      ),
    });
  };
  const addTag = (tagId) => {
    setFormData({
      ...formData,
      tagModels: [...(formData.tagModels || []), tagId],
    });
  };

  const removeTag = (tagId) => {
    setFormData({
      ...formData,
      tagModels: (formData.tagModels || []).filter((c) => c !== tagId),
    });
  };

  useEffect(() => {
    if (data) setFormData(data);
  }, [data]);

  return (
    <StyledGuideEntryForm>
      <form onSubmit={onSubmit}>
        <Checkbox
          className="mbottom1"
          label="Publicerad"
          checked={formData.published}
          onChange={(e) =>
            setFormData({
              ...formData,
              published: e.target.checked,
            })
          }
        />
        <Checkbox
          className="mbottom1"
          label="Ange som utvald"
          checked={formData.featured}
          onChange={(e) =>
            setFormData({
              ...formData,
              featured: e.target.checked,
            })
          }
        />
        <Input
          label="Övningens namn"
          type="text"
          value={formData.title}
          name="title"
          placeholder="Övningsnamn"
          required
          disabled={loading}
          onChange={handleChange}
        />
        <Select
          value=""
          onChange={(e) => addCategory(e.target.value)}
          label="Kategorier"
        >
          <option disabled value="">
            Lägg till kategori
          </option>
          {categories.map((category) => (
            <option key={category._id} value={category._id}>
              {category.text}
            </option>
          ))}
        </Select>
        <div className="tags-container">
          {!!formData.categoryModels &&
            formData.categoryModels.map((category) => (
              <Tag
                bgColor={colors.primary.main}
                key={category}
                tagName={getCategoryName(category)}
                remove={() => removeCategory(category)}
                onWhite
              />
            ))}
        </div>
        <Select
          value=""
          onChange={(e) => addTag(e.target.value)}
          label="Taggar"
        >
          <option disabled value="">
            Lägg till tagg
          </option>
          {tags.map((tag) => (
            <option key={tag._id} value={tag._id}>
              {tag.text}
            </option>
          ))}
        </Select>
        <div className="tags-container">
          {!!formData.tagModels &&
            formData.tagModels.map((tag) => (
              <Tag
                key={tag}
                tagName={getTagName(tag)}
                remove={() => removeTag(tag)}
                onWhite
              />
            ))}
        </div>
        <FileInput
          label="Bild"
          outlet={outlet}
          value={formData.image}
          name="image"
          onChange={handleChange}
        />
        <YoutubeInput
          label="Youtube-länk"
          outlet={outlet}
          value={formData.videoUrl}
          name="videoUrl"
          onChange={handleChange}
        />
        <RichTextInput
          label="Textinnehåll"
          value={formData.richTextHtml}
          name="richTextHtml"
          onChange={handleChange}
        />

        <div className="flex jce">
          <Button type="submit" disabled={loading}>
            {loading ? "Sparar..." : "Spara"}
          </Button>
        </div>
      </form>
    </StyledGuideEntryForm>
  );
};

export default GuideEntryForm;
