import { useEffect, useState } from "react";
import styled from "styled-components";
import Input from "../../sharedUI/Input";
import Button from "../../sharedUI/Button";
import Checkbox from "../../sharedUI/Checkbox";
import TagsInput from "../../sharedUI/TagsInput";
import FileInput from "../../sharedUI/FileInput";
import PartEdit from "../../routes/protected/PartEdit";
import { parseDate } from "../../utils/parseDate";

const StyledEventPlanForm = styled.div`
  form {
    max-width: 600px;
  }
`;

const EventPlanForm = ({ handleSubmit, data, outlet }) => {
  const initialData = {
    title: "",
    image: null,
    tags: [],
    published: false,
    contents: [],
    featured: false,
    eventDate: "",
    endDate: "",
  };

  const [formData, setFormData] = useState(initialData);
  const [loading, setLoading] = useState(false);

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const onSubmit = (e) => {
    setLoading(true);
    e.preventDefault();
    handleSubmit(
      formData,
      () => {
        setFormData(initialData);
        setLoading(false);
      },
      () => setLoading(false)
    );
  };

  useEffect(() => {
    if (data) setFormData(data);
  }, [data]);

  return (
    <StyledEventPlanForm>
      <form onSubmit={onSubmit}>
        <Checkbox
          className="mbottom1"
          label="Publicerad"
          checked={formData.published}
          onChange={(e) =>
            setFormData({
              ...formData,
              published: e.target.checked,
            })
          }
        />
        <Checkbox
          className="mbottom1"
          label="Ange som aktuell"
          checked={formData.featured}
          onChange={(e) =>
            setFormData({
              ...formData,
              featured: e.target.checked,
            })
          }
        />
        <Input
          label="Evenemangets namn"
          type="text"
          value={formData.title}
          name="title"
          placeholder="Evenemangsnamn"
          required
          disabled={loading}
          onChange={handleChange}
        />
        <Input
          label="Evenemangets startdatum"
          type="date"
          name="eventDate"
          value={formData.eventDate ? parseDate(formData.eventDate) : ""}
          required
          disabled={loading}
          onChange={handleChange}
        />
        <Input
          label="Evenemangets slutdatum"
          type="date"
          name="endDate"
          value={formData.endDate ? parseDate(formData.endDate) : ""}
          required
          disabled={loading}
          onChange={handleChange}
        />
        <Input
          label="Ingress"
          type="textarea"
          placeholder="Visas på evenemangskortet"
          name="ingress"
          value={formData.ingress}
          disabled={loading}
          onChange={handleChange}
        />
        <TagsInput
          label="Taggar"
          name="tags"
          value={formData.tags}
          onChange={handleChange}
        />
        <FileInput
          label="Bild"
          outlet={outlet}
          value={formData.image}
          name="image"
          onChange={handleChange}
        />
        <p className="label">Innehåll</p>
        <PartEdit
          onEvent
          outlet={outlet}
          setPart={({ contents }) => setFormData({ ...formData, contents })}
          part={{ contents: formData.contents }}
          mTop={"0"}
        />
        <div className="flex jce">
          <Button type="submit" disabled={loading}>
            {loading ? "Sparar..." : "Spara"}
          </Button>
        </div>
      </form>
    </StyledEventPlanForm>
  );
};

export default EventPlanForm;
