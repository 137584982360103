import styled from "styled-components";
import SVG from "../../sharedUI/SVG";
import { colors, measurments } from "../../utils/config";

const StyledToolbar = styled.div`
  border-radius: ${measurments.borderRadius}px;
  background: white;
  padding-bottom: 1.5rem;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.08);
  .toolbar-header {
    color: ${colors.neutral[20]};
    background: ${colors.neutral[80]};
    display: flex;
    padding: 0.5rem 1rem;
    border-radius: ${measurments.borderRadius}px;
    p {
      margin-left: 0.5rem;
    }
  }
  .toolbar-item {
    padding: 0.5rem 1rem;
    color: ${colors.primary.hover};
  }
`;

const Toolbar = ({ course }) => {
  return (
    <StyledToolbar>
      <div className="toolbar-header">
        <SVG name="cardholder" stroke="white" />
        <p className="b2 m0">Översikt innehåll</p>
      </div>
      <div className="toolbar-item b2">Grunduppgifter</div>
      {course.parts.map((part) => (
        <div className="toolbar-item b2" key={`toolbar_${part.uuid}`}>
          {part.name}
        </div>
      ))}
      <div className="toolbar-item b2">Kunskapstest</div>
    </StyledToolbar>
  );
};

export default Toolbar;
