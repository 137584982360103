import { useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import Input from "../../sharedUI/Input";
import Button from "../../sharedUI/Button";
import Select from "../../sharedUI/Select";
import FormTagCard from "../FormTagCard";

const StyledGuideCategoryForm = styled.div`
  form {
    max-width: 600px;
  }
`;

const GuideCategoryForm = ({ handleSubmit, data, tags }) => {
  const initialData = {
    text: "",
    sortIndex: 0,
    tags: [],
  };

  const [formData, setFormData] = useState(initialData);
  const [loading, setLoading] = useState(false);

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleAddTag = (tagId) => {
    setFormData({
      ...formData,
      tags: [...formData.tags, tagId],
    });
  };

  const handleRemoveTag = (tagId) => {
    setFormData({
      ...formData,
      tags: formData.tags.filter((t) => t !== tagId),
    });
  };

  const moveTagUp = (index) => {
    if (index === 0) return;
    const newTags = [...formData.tags];
    const temp = newTags[index];
    newTags[index] = newTags[index - 1];
    newTags[index - 1] = temp;
    setFormData({ ...formData, tags: newTags });
  };

  const moveTagDown = (index) => {
    if (index === formData.tags.length - 1) return;
    const newTags = [...formData.tags];
    const temp = newTags[index];
    newTags[index] = newTags[index + 1];
    newTags[index + 1] = temp;
    setFormData({ ...formData, tags: newTags });
  };

  const onSubmit = (e) => {
    setLoading(true);
    e.preventDefault();
    handleSubmit(
      formData,
      () => {
        setFormData(initialData);
        setLoading(false);
      },
      () => setLoading(false)
    );
  };

  const availableTags = useMemo(() => {
    if (!tags) return [];
    const formDataTags = formData.tags || [];

    return tags.filter((tag) => !formDataTags.includes(tag._id));
  }, [tags, formData.tags]);

  const getTagName = (tagId) => {
    return tags.find((t) => t._id === tagId)?.text || "";
  };

  useEffect(() => {
    if (data) setFormData(data);
  }, [data]);

  return (
    <StyledGuideCategoryForm>
      <form onSubmit={onSubmit}>
        <Input
          label="Kategorins namn"
          type="text"
          value={formData.text}
          name="text"
          required
          disabled={loading}
          onChange={handleChange}
        />
        <Input
          label="Sortering"
          type="number"
          description="Lägre värde visas först"
          value={formData.sortIndex || 0}
          name="sortIndex"
          disabled={loading}
          onChange={handleChange}
        />
        <Select
          value=""
          label="Taggar"
          onChange={(e) => handleAddTag(e.target.value)}
        >
          <option disabled value="">
            Lägg till tagg
          </option>
          {availableTags.map((tag) => (
            <option key={tag._id} value={tag._id}>
              {tag.text}
            </option>
          ))}
        </Select>

        <div className="tags">
          {formData.tags.map((tag, i) => (
            <FormTagCard
              key={`existingTag_${tag}`}
              getTagName={getTagName}
              tagId={tag}
              removeTag={() => handleRemoveTag(tag)}
              moveTagUp={() => moveTagUp(i)}
              moveTagDown={() => moveTagDown(i)}
            />
          ))}
        </div>

        <div className="flex jce">
          <Button type="submit" disabled={loading}>
            {loading ? "Sparar..." : "Spara"}
          </Button>
        </div>
      </form>
    </StyledGuideCategoryForm>
  );
};

export default GuideCategoryForm;
