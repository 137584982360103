import styled from "styled-components";
import { colors, measurments } from "../utils/config";

const StyledSelect = styled.div`
  display: flex;
  flex-direction: column;
  label {
    display: block;
    margin-bottom: 0.25rem;
    color: ${colors.neutral[90]};
  }
  select {
    padding: 0.375rem 0.75rem;
    border-radius: ${measurments.borderRadius}px;
    border: 1px solid ${colors.neutral[50]};
    background: ${colors.neutral[10]};
    width: 100%;
  }
  margin-bottom: 0.95rem;
`;

const Select = (props) => {
  return (
    <StyledSelect className={props.className}>
      {!!props.label && (
        <label htmlFor={props.id || props.name} className="b2">
          {props.label}
        </label>
      )}
      <select
        {...props}
        id={props.id || props.name}
        className="b2"
        type={props.type}
      />
    </StyledSelect>
  );
};

export default Select;
