import styled from "styled-components";
import Input from "../../../sharedUI/Input";

const StyledHeaderEdit = styled.div``;

const HeaderEdit = ({ content, setContentData }) => {
  return (
    <StyledHeaderEdit>
      <Input
        label="Rubriktext"
        type="text"
        value={content.data.text}
        onChange={(e) =>
          setContentData({ ...content.data, text: e.target.value })
        }
        placeholder="Rubriktext"
      />
    </StyledHeaderEdit>
  );
};

export default HeaderEdit;
