import styled from "styled-components";
import EditableContent from "../../components/contentComponents/edit/EditableContent";
import ContentSelector from "../../components/courseEdit/ContentSelector";
import { v4 as uuid } from "uuid";

const StyledPartEdit = styled.div``;

const PartEdit = ({ part, setPart, mTop, outlet, onEvent }) => {
  const setContentData = (data, contentIndex) => {
    const tempContents = [...part.contents];
    tempContents[contentIndex].data = data;
    setPart({
      ...part,
      contents: tempContents,
    });
  };

  const addContent = (contentType) => {
    const tempContents = Array.from(part.contents);
    tempContents.push({ ...contentType, uuid: uuid() });
    setPart({
      ...part,
      contents: tempContents,
    });
  };

  const deleteContent = (contentIndex) => {
    const tempContents = Array.from(part.contents);
    tempContents.splice(contentIndex, 1);
    setPart({
      ...part,
      contents: tempContents,
    });
  };

  const moveContentDown = (contentIndex) => {
    if (contentIndex >= part.contents.length - 1) return;
    const updated = Array.from(part.contents);
    const [reordered] = updated.splice(contentIndex, 1);
    updated.splice(contentIndex + 1, 0, reordered);
    setPart({
      ...part,
      contents: updated,
    });
  };

  const moveContentUp = (contentIndex) => {
    if (contentIndex === 0) return;
    const updated = Array.from(part.contents);
    const [reordered] = updated.splice(contentIndex, 1);
    updated.splice(contentIndex - 1, 0, reordered);
    setPart({
      ...part,
      contents: updated,
    });
  };

  return (
    <StyledPartEdit>
      {part.contents.map((content, contentIndex) => (
        <EditableContent
          outlet={outlet}
          moveContentUp={() => moveContentUp(contentIndex)}
          moveContentDown={() => moveContentDown(contentIndex)}
          key={content.uuid}
          deleteContent={() => deleteContent(contentIndex)}
          content={content}
          setContentData={(data) => setContentData(data, contentIndex)}
        />
      ))}
      <ContentSelector addContent={addContent} mTop={mTop} onEvent={onEvent} />
    </StyledPartEdit>
  );
};

export default PartEdit;
