import axios from "axios";
import { useEffect, useMemo, useState } from "react";
import { useOutletContext } from "react-router-dom";
import styled from "styled-components";
import { breakPoints, colors, DOServer } from "../../utils/config";
import { v4 as uuid } from "uuid";
import MediaItem from "../cards/MediaItem";
import DeleteConfirmation from "../forms/DeleteConfirmation";
import FileForm from "../forms/FileForm";
import Plus from "./Plus";
import ResponsiveImage from "../contentComponents/view/ResponsiveImage";
import ResponsiveVideo from "../contentComponents/view/ResponsiveVideo";
import Input from "../../sharedUI/Input";
import { fetcher } from "../../utils/fetcher";
import { Loader } from "../../sharedUI/FullScreenLoader";
import IconButton from "../../sharedUI/IconButton";
import { pdfUrl } from "../../utils/pdfUrl";

const StyledMediaLibrary = styled.div`
  #fileInput {
    display: none;
  }
  .spotlight {
    position: fixed;
    inset: 0;
    width: 100vw;
    height: 100vh;
    z-index: 20;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.9);
    padding: 2rem;
    &-close {
      position: absolute;
      top: 0.5rem;
      right: 0.5rem;
      cursor: pointer;
    }
    img {
      width: 100%;
      height: 100%;
      max-height: 100vh;
      max-width: 100vw;
      object-fit: contain;
    }
  }
  .search-box {
    margin: 0;
    margin-right: 0.5rem;
  }
  .media-grid-container {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  .list-grid {
    display: grid;
    grid-template-columns: 80px 3fr 3fr 1fr 2fr ${({ handleSelect }) =>
        handleSelect ? "4rem" : "24px"};
    gap: 1rem;
    height: 60px;
    padding-right: 0.5rem;
    align-items: center;
    p {
      margin: 0;
      white-space: nowrap;
      overflow: hidden;
    }
  }
  .list-grid-headers {
    background: ${colors.neutral[20]};
    .img-placeholder {
      background: ${colors.neutral[50]};
      align-self: stretch;
    }
  }
  .mobile-additional-info {
    display: none;
  }
  ${breakPoints.tablet} {
    .mobile-additional-info {
      display: block;
    }
    .media-top {
      display: block;
      .flex {
        justify-content: flex-end;
      }
    }
    .list-grid {
      grid-template-columns: 80px 1fr ${({ handleSelect }) =>
          handleSelect ? "4rem" : "24px"};
      .used-in,
      .type,
      .upload-date {
        display: none;
      }
    }
  }
`;

const MediaLibrary = ({ outlet, handleSelect, type }) => {
  //Global
  let o = useOutletContext() || outlet;
  const { globalState, notify, modal, closeModal } = o;

  //States
  const [files, setFiles] = useState();
  const [uploading, setUploading] = useState(false);
  const [spotlight, setSpotlight] = useState();
  const [searchString, setSearchString] = useState("");
  const [filteredFiles, setFilteredFiles] = useState([]);
  const [loading, setLoading] = useState(true);

  const uploadAccept = useMemo(() => {
    if (type === "pdf") return ".pdf";
    if (type === "image") return ".png,.jpg,.jpeg";
    return ".png,.jpg,.jpeg,.pdf";
  }, [type]);

  //Functions
  const getFiles = async () => {
    setLoading(true);
    const { err, data } = await fetcher({
      path: "files-admin",
      server: DOServer,
      auth: globalState.token,
    });
    if (err) {
      notify.error("Kunde inte hämta filer");
      setLoading(false);
      return;
    }
    let ret = Array.from(data);
    if (type) ret = ret.filter((file) => file.type === type);
    setFiles(ret);
    setLoading(false);
  };

  const uploadButtonPress = () => {
    const fileInput = document.getElementById("fileInput");
    fileInput.click();
  };

  const handleUpload = (e) => {
    const files = e.target.files;
    if (!files?.length) return;
    setUploading(true);
    setLoading(true);
    const formData = new FormData();
    for (let i = 0; i < files.length; i++) {
      formData.append(uuid(), files[i]);
    }
    axios
      .post(`${DOServer}files-admin`, formData, {
        headers: {
          Authorization: "Bearer " + globalState.token,
          "Content-Type": "multipart/form-data",
        },
      })
      .then(() => {
        notify.success("Filerna laddades upp");
        setUploading(false);
        getFiles();
        e.target.value = "";
      })
      .catch(() => {
        setUploading(false);
        setLoading(false);
        e.target.value = "";
        notify.error("Kunde inte ladda upp filer");
      });
  };

  const deleteFileInit = (file) => {
    modal(
      <DeleteConfirmation
        item={file}
        deleteKey={"name"}
        handleInvalidKey={() => notify.error("Skriv in rätt värde i fältet")}
        action={deleteFile}
        name="filen"
        instruction="filens namn"
      />,
      "Radera filen?"
    );
  };

  const deleteFile = async (file, cb, errCb) => {
    setLoading(true);
    const { err } = await fetcher({
      path: `files-admin/${file.uuid}`,
      method: "DELETE",
      server: DOServer,
      auth: globalState.token,
    });
    if (err) {
      notify.error("Kunde inte radera filen");
      errCb();
      setLoading(false);
      return;
    }
    notify.success("Filen raderades");
    cb();
    closeModal();
    getFiles();
  };

  const updateFileInit = (file) => {
    modal(<FileForm data={file} handleSubmit={updateFile} />, "Byt filnamn");
  };

  const handleSpotlight = (file) => {
    if (file.type === "pdf") {
      window.open(pdfUrl(file));
      return;
    }
    setSpotlight(file);
  };

  const updateFile = async (data, cb, errCb) => {
    setLoading(true);
    const { err } = await fetcher({
      path: `files-admin/${data.uuid}`,
      server: DOServer,
      method: "POST",
      body: { name: data.name },
      auth: globalState.token,
    });
    if (err) {
      errCb();
      notify.error("Kunde inte uppdatera fil");
      setLoading(false);
      return;
    }
    cb();
    notify.success("Filen uppdaterades");
    closeModal();
    getFiles();
  };

  //Effects
  useEffect(() => {
    getFiles();
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (searchString) {
      const s = searchString.toLocaleLowerCase();
      let matches = [];
      files.forEach((object) => {
        const search = object.name.toLocaleLowerCase().search(s);
        if (search !== -1) matches.push(object);
      });
      setFilteredFiles(matches);
    } else {
      setFilteredFiles(files);
    }
    //eslint-disable-next-line
  }, [searchString, files]);

  return (
    <StyledMediaLibrary handleSelect={handleSelect}>
      <div className="media-top flex jcsb ac mbottom1 mtop2">
        <h2 className="m0">Media</h2>
        <div className="flex gap05 ac">
          <Input
            placeholder="Sök fil"
            type="text"
            className="search-box"
            value={searchString}
            onChange={(e) => setSearchString(e.target.value)}
          />
          <IconButton
            iconRight="plus"
            onClick={uploadButtonPress}
            disabled={uploading}
          >
            {uploading ? "Laddar upp..." : "Ladda upp"}
          </IconButton>
        </div>
      </div>
      <input
        type="file"
        id="fileInput"
        accept={uploadAccept}
        multiple
        onChange={handleUpload}
      />
      <div className="media-grid-container">
        <div className="list-grid list-grid-headers">
          <div className="img-placeholder" />
          <p>Filnamn</p>
          <p className="used-in">Används i</p>
          <p className="type">Filtyp</p>
          <p className="upload-date">Uppladdningsdatum</p>
        </div>
        {loading ? (
          <Loader />
        ) : (
          <>
            {filteredFiles?.length ? (
              filteredFiles.map((file) => (
                <MediaItem
                  handleSelect={handleSelect}
                  file={file}
                  key={file.uuid}
                  spotlight={() => handleSpotlight(file)}
                  deleteFile={deleteFileInit}
                  updateFile={updateFileInit}
                />
              ))
            ) : (
              <p className="c p1 pbottom2">Inga filer hittades</p>
            )}
          </>
        )}
      </div>
      {spotlight && (
        <div
          className="spotlight"
          onClick={() => {
            setSpotlight(null);
          }}
        >
          <div className="spotlight-close">
            <Plus cross color={"white"} />
          </div>
          {spotlight.type === "image" && <ResponsiveImage file={spotlight} />}
          {spotlight.type === "video" && <ResponsiveVideo file={spotlight} />}
        </div>
      )}
    </StyledMediaLibrary>
  );
};

export default MediaLibrary;
