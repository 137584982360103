import styled from "styled-components";
import { colors } from "../utils/config";

const StyledCheckbox = styled.div`
  transition: 200ms ease-in-out;
  background: ${({ state }) =>
    state ? colors.primary.main : colors.neutral[40]};
  height: 20px;
  width: 50px;
  border-radius: 50px;
  border: 1px solid ${colors.primary.hover};
  position: relative;
  cursor: pointer;
  .mover {
    transition: 200ms ease-in-out;
    width: 16px;
    height: 16px;
    background: ${colors.neutral[70]};
    border-radius: 50%;
    position: absolute;
    left: ${({ state }) => (state ? "calc(100% - 17px)" : "1px")};
    top: 1px;
  }
`;

const StyledLabel = styled.span`
  display: block;
  margin-bottom: 0.25rem;
  color: ${colors.neutral[90]};
`;

const Checkbox = ({ checked, onChange, name, label, className }) => {
  const handleChange = () => {
    const e = {
      target: {
        name,
        checked: !checked,
      },
    };
    onChange(e);
  };
  return (
    <>
      {!!label && <StyledLabel className="b2">{label}</StyledLabel>}
      <StyledCheckbox
        onClick={handleChange}
        state={checked}
        className={className}
      >
        <div className="mover" />
      </StyledCheckbox>
    </>
  );
};

export default Checkbox;
