import styled from "styled-components";
import Input from "./Input";
import { useState } from "react";
import Tag from "./Tag";

const StyledTagsInput = styled.div`
  .tags-container {
    display: flex;
    gap: 0.5rem;
    flex-wrap: wrap;
    margin-bottom: 1rem;
  }
`;

const TagsInput = ({
  value,
  onChange,
  label,
  name,
  placeholder = "Skriv in en tagg och tyck på enter",
}) => {
  const [currentTag, setCurrentTag] = useState("");

  const handleRemove = (i) => {
    const updated = [...value];
    updated.splice(i, 1);
    onChange({ target: { name, value: updated } });
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      if (!currentTag) return;
      const updated = [...value];
      updated.push(currentTag);
      setCurrentTag("");
      const deduped = [...new Set(updated)];
      onChange({ target: { name, value: deduped } });
    }
  };

  return (
    <StyledTagsInput>
      <Input
        label={label}
        value={currentTag}
        onChange={(e) => setCurrentTag(e.target.value)}
        placeholder={placeholder}
        onKeyDown={handleKeyDown}
      />
      {!!value.length && (
        <div className="tags-container">
          {value.map((tag, i) => (
            <Tag
              key={`${tag}_${i}`}
              tagName={tag}
              remove={() => handleRemove(i)}
              onWhite
            />
          ))}
        </div>
      )}
    </StyledTagsInput>
  );
};

export default TagsInput;
