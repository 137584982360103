import { useMemo, useState } from "react";
import styled from "styled-components";
import SVG from "../../sharedUI/SVG";
import { colors, measurments } from "../../utils/config";
import { contentTypes } from "../../utils/contentTypes";

const StyledContentSelector = styled.div`
  margin-top: ${({ _mTop }) => _mTop || "2rem"};
  margin-bottom: 1.5rem;
  .content-type-button {
    button {
      cursor: pointer;
      background: none;
      border: none;
      border: 1px solid ${colors.neutral[60]};
      border-radius: ${measurments.borderRadius}px;
      color: ${colors.primary.hover};
      font-weight: 500;
      font-size: 14px;
      padding: 0.5rem 1rem;
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
    }
  }
  .content-types {
    display: flex;
    flex-wrap: wrap;
    max-height: 0;
    overflow: hidden;
    padding: 0;
    margin: 0;
    transition: 200ms ease-in-out;
    gap: 0.4rem;
    .content-type {
      display: flex;
      border-radius: ${measurments.borderRadius}px;
      padding: 0.5rem 1rem;
      background: ${colors.neutral[20]};
      border: 1px solid ${colors.neutral[60]};
      align-items: center;
      cursor: pointer;
      p {
        white-space: nowrap;
        margin-right: 0.5rem;
        font-size: 14px;
        color: ${colors.primary.hover};
      }
    }
  }
  &.is-open {
    .content-types {
      max-height: 200px;
      margin-top: 0.5rem;
    }
  }
`;

const ContentSelector = ({ addContent, mTop, onEvent }) => {
  const [isOpen, setIsOpen] = useState(false);

  const types = useMemo(() => {
    if (onEvent) return contentTypes;
    return contentTypes.filter((type) => {
      if (type.onlyOnEvents) return false;
      return true;
    });
  }, [onEvent]);

  return (
    <StyledContentSelector className={isOpen ? "is-open" : ""} _mTop={mTop}>
      <div className="content-type-button">
        <button onClick={() => setIsOpen(!isOpen)} type="button">
          <span>Lägg till innehåll</span>

          <SVG name={isOpen ? "minus" : "plus"} stroke="black" />
        </button>
      </div>
      <div className="content-types">
        {types.map((contentType) => (
          <div
            className="content-type"
            key={contentType.type}
            role="button"
            onClick={() => {
              addContent(contentType);
              setIsOpen(false);
            }}
          >
            <p className="m0">{contentType.name}</p>
            <SVG name={contentType.icon} stroke={colors.primary.hover} />
          </div>
        ))}
      </div>
    </StyledContentSelector>
  );
};

export default ContentSelector;
