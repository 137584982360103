import { useEffect, useState } from "react";
import styled from "styled-components";
import Input from "../../sharedUI/Input";
import Button from "../../sharedUI/Button";

const StyledGuideTagForm = styled.div`
  form {
    max-width: 600px;
  }
`;

const GuideTagForm = ({ handleSubmit, data }) => {
  const initialData = {
    text: "",
  };

  const [formData, setFormData] = useState(initialData);
  const [loading, setLoading] = useState(false);

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const onSubmit = (e) => {
    setLoading(true);
    e.preventDefault();
    handleSubmit(
      formData,
      () => {
        setFormData(initialData);
        setLoading(false);
      },
      () => setLoading(false)
    );
  };

  useEffect(() => {
    if (data) setFormData(data);
  }, [data]);

  return (
    <StyledGuideTagForm>
      <form onSubmit={onSubmit}>
        <Input
          label="Taggens namn"
          type="text"
          value={formData.text}
          name="text"
          required
          disabled={loading}
          onChange={handleChange}
        />

        <div className="flex jce">
          <Button type="submit" disabled={loading}>
            {loading ? "Sparar..." : "Spara"}
          </Button>
        </div>
      </form>
    </StyledGuideTagForm>
  );
};

export default GuideTagForm;
