import styled from "styled-components";
import RichTextEdit from "../components/contentComponents/edit/RichTextEdit";

const StyledRichTextInput = styled.div`
  margin-bottom: 1rem;
`;

const RichTextInput = ({ name, onChange, value, label }) => {
  const handleChange = (e) => {
    const event = {
      target: {
        name,
        value: e.html,
      },
    };
    onChange(event);
  };

  return (
    <StyledRichTextInput>
      {!!label && <span className="b2">{label}</span>}
      <RichTextEdit
        content={{ data: { html: value || "" } }}
        setContentData={handleChange}
      />
    </StyledRichTextInput>
  );
};

export default RichTextInput;
