import React, { useState } from "react";
import styled from "styled-components";
import SVG from "../../sharedUI/SVG";
import { colors, measurments } from "../../utils/config";

const StyledExpandCard = styled.div`
  background: white;
  border-radius: ${measurments.borderRadius}px;
  transition: 200ms ease-in-out;
  margin-bottom: 1rem;
  border-radius: ${measurments.borderRadius}px;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1);
  .expand-card-header {
    padding: 1rem 1.5rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    input {
      width: 100%;
      max-width: 500px;
      border: none;
      font-size: 1rem;
      font-family: "SofiaPro-Regular", sans-serif;
      line-height: 1.5rem;

      &:focus {
        outline: none;
      }
    }
    .expand-card-header-buttons {
      display: flex;
      .svg-button {
        margin-left: 0.3rem;
        cursor: pointer;
        svg {
          width: 20px;
          height: 20px;
        }
      }
    }
  }
  .expand-card-content {
    max-height: 0;
    padding: 0 1.5rem;
    transition: 200ms ease-in-out;
    overflow: hidden;
    color: ${colors.black};
  }
  &.open {
    .expand-card-content {
      padding: 0 1.5rem;
      max-height: 200vh;
      overflow: scroll;
    }
  }
`;

const ExpandCard = (props) => {
  const [isOpen, setIsOpen] = useState(false);

  const { headerContent, mainContent, buttons } = props;
  return (
    <StyledExpandCard
      className={isOpen ? `open ${props.className}` : props.className}
    >
      <div className="expand-card-header">
        {headerContent}
        <div className="expand-card-header-buttons">
          {buttons}
          <div className="svg-button" onClick={() => setIsOpen(!isOpen)}>
            <SVG
              name={isOpen ? "minus" : "plus"}
              stroke={colors.primary.hover}
              // color={isOpen ? colors.blue1 : colors.darkGrey3}
              // minus={isOpen}
            />
          </div>
        </div>
      </div>
      <div className="expand-card-content">{mainContent}</div>
    </StyledExpandCard>
  );
};

export default ExpandCard;
