import { useState } from "react";
import styled from "styled-components";
import Button from "../../sharedUI/Button";
import { colors } from "../../utils/config";

const StyledRegisterLinkForm = styled.div`
  code {
    background: ${colors.neutral[30]};
    padding: 0.3rem;
    display: inline-block;
  }
`;

const RegisterLinkForm = ({ generate, closeModal, notify }) => {
  const [loading, setLoading] = useState(false);
  const [link, setLink] = useState("");

  const createLink = async () => {
    setLoading(true);
    const link = await generate();
    if (!link) {
      notify.error("Något gick fel");
      setLoading(false);
      return;
    }
    setLink(link);
    setLoading(false);
  };

  return (
    <StyledRegisterLinkForm>
      <p>
        Notera att vem som helst med tillgång till länken kan skapa ett konto i
        tränarportalen.
        <br />
        Länken är giltig i 1 vecka från att den genereras.
      </p>
      {link ? (
        <>
          <p>Kopiera länken nedan</p>
          <code>{link}</code>
          <br />
          <Button className="mtop1" onClick={closeModal}>
            Klar
          </Button>
        </>
      ) : (
        <Button disabled={loading} onClick={createLink}>
          {loading ? "Skapar länk..." : "Skapa länk"}
        </Button>
      )}
    </StyledRegisterLinkForm>
  );
};

export default RegisterLinkForm;
